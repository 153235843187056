import { AvailableIntegrationTypes } from '../configuration/types';
import { Nullable } from '../../types';

export const retrieveAccessTokenFromUrl = (
  integrationType: AvailableIntegrationTypes,
): Nullable<string> => {
  const mapTokenFieldNameToAccount: Partial<Record<
    AvailableIntegrationTypes,
    string
  >> = {
    [AvailableIntegrationTypes.Facebook]: 'access_token',
    [AvailableIntegrationTypes.Google]: 'code',
  };
  const accessTokenFieldName = mapTokenFieldNameToAccount[integrationType];
  const url =
    integrationType === AvailableIntegrationTypes.Google
      ? window.location.href
      : window.location.hash.substring(1);
  const searchParams = new URLSearchParams(url);
  const accessToken = searchParams.get(accessTokenFieldName as string);

  return accessToken;
};

export const hasAnyOfAccessTokenInUrl = (): boolean => {
  const allAvailableIntegrations = Object.values(AvailableIntegrationTypes);
  const valuation = allAvailableIntegrations.map(
    (type: AvailableIntegrationTypes) => {
      const token = retrieveAccessTokenFromUrl(type);
      return token != null;
    },
  );
  const occurrencesWithToken = valuation.filter((v) => v);

  return occurrencesWithToken.length > 0;
};
