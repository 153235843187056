import { Operator } from 'components/PatternMatcher/OperatorEnum';
import { PatternType } from 'components/PatternMatcher/PatternEnum';
import { SelectValue } from 'features/generate/audience/consts';
import { MetadataTree } from 'features/generate/audience/types';
import { Breadcrumb, BreadcrumbNavigation, IconButton, Icons } from 'plume-ui';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getMetadataByColumnNameSelector } from 'store/state/metadataTreeState';
import { generateKey } from '../../utils/helpers';
import { SelectedCriteriaType } from '../CriteriaCollapseCard/CriteriaCollapseCard';

export type SelectedCriteriaBreadcrumbProps = {
  criteria: any;
  handleDeleteGroup: (
    criteria: SelectedCriteriaType,
    criteriaIdx: number,
  ) => void;
  handleEdit: (index: number, nestedIndex?: number) => void;
  patternMode: boolean;
  criteriaIndex: number;
  cardFilterArrayIndex?: number;
};

const SelectedCriteriaBreadcrumb: FunctionComponent<SelectedCriteriaBreadcrumbProps> = ({
  criteria,
  handleDeleteGroup,
  handleEdit,
  criteriaIndex,
  cardFilterArrayIndex,
}) => {
  const { t } = useTranslation();
  const metaTreeItem = useRecoilValue(
    getMetadataByColumnNameSelector(criteria.columnName),
  );

  const editMode = () => {
    // if there is no cardFilterIndex, it's an include or exclude so we only
    //  need the criteriaIndex
    cardFilterArrayIndex !== undefined
      ? handleEdit(criteriaIndex, cardFilterArrayIndex)
      : handleEdit(criteriaIndex);
  };

  const isPatternString = (metaData: MetadataTree): boolean => {
    if (
      metaData &&
      metaData.pattern === 'string' &&
      metaData.allowedValues.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const pluralizeDate = (date: number) => {
    if (date > 0) {
      return date === 1 ? 'day' : 'days';
    } else {
      return date === -1 ? 'day ' : 'days';
    }
  };

  const renderBetweenDaysValue = () => {
    const startDay = Math.abs(criteria.columnValue.startDays);
    const endDay = Math.abs(criteria.columnValue.endDays);
    const startLabel =
      criteria.columnValue.startDays < 0
        ? t('patternMatcher.next')
        : t('patternMatcher.past');

    const endLabel =
      criteria.columnValue.endDays < 0
        ? t('patternMatcher.next')
        : t('patternMatcher.past');

    return (
      <span>
        {`${startLabel} ${startDay} ${pluralizeDate(
          startDay,
        )} - ${endLabel} ${endDay} ${pluralizeDate(endDay)}`}
      </span>
    );
  };

  const displaySelectValue = () => {
    return (
      <span
        className="CriteriaCollapseCard__selectValue"
        onClick={() => {
          editMode();
        }}
      >
        ! {criteria.columnValue}
      </span>
    );
  };

  const renderBreadcrumbValue = () => {
    switch (criteria.operator) {
      case Operator.BETWEEN_DATES:
        return (
          <span>
            {criteria.columnValue === SelectValue
              ? displaySelectValue()
              : `(${criteria.columnValue.startDate} - ${criteria.columnValue.endDate})`}
          </span>
        );

      case Operator.BETWEEN:
        return (
          <span>
            {criteria.columnValue === SelectValue
              ? displaySelectValue()
              : `(${criteria.columnValue.start} - ${criteria.columnValue.end})`}
          </span>
        );

      case Operator.BETWEEN_DAYS:
        return (
          <span>
            {criteria.columnValue === SelectValue
              ? displaySelectValue()
              : renderBetweenDaysValue()}
          </span>
        );

      case Operator.EQUAL:
        return (
          <span
            className={
              [
                PatternType.NUMERIC,
                PatternType.TIME,
                PatternType.STRING,
              ].includes(metaTreeItem?.pattern as PatternType)
                ? 'CriteriaCollapseCard__selectValue'
                : 'CriteriaCollapseCard__breadcrumbValue'
            }
          >
            {criteria.columnValue === SelectValue
              ? displaySelectValue()
              : `${criteria.operator} ${criteria.columnValue}`}
          </span>
        );

      case Operator.AT_LEAST:
      case Operator.AT_MOST:
      case Operator.MORE_THAN:
      case Operator.LESS_THAN:
      case Operator.NOT_EQUAL:
      case Operator.ANY_TIME_PAST:
      case Operator.DAYS_AGO:
      case Operator.NOW:
      case Operator.EACH_OF_LAST:
      case Operator.NEXT:
        return (
          <span>
            {criteria.columnValue === SelectValue
              ? displaySelectValue()
              : `${criteria.operator} ${criteria.columnValue}`}
          </span>
        );

      case Operator.IN:
        return (
          <span>
            {criteria.columnValue === SelectValue
              ? displaySelectValue()
              : t('segmentsModal.selected', {
                  number: criteria.columnValue.length,
                })}
          </span>
        );

      default:
        return (
          <span>
            {isPatternString(metaTreeItem!) ? (
              t('segmentsModal.selected', {
                number: criteria.columnValue.length,
              })
            ) : criteria.columnValue === SelectValue ? (
              <span
                className="CriteriaCollapseCard__selectValue"
                onClick={() => {
                  editMode();
                }}
              >
                ! {criteria.columnValue}
              </span>
            ) : (
              <span className="CriteriaCollapseCard__breadcrumbValue">
                {criteria.columnValue}
              </span>
            )}
          </span>
        );
    }
  };

  return (
    <div
      className="CriteriaCollapseCard__selectedCriteriaGroup"
      key={generateKey()}
    >
      <div className="CriteriaCollapseCard__selectedCriteria">
        <div className="CriteriaCollapseCard__selectedBreadcrumbsAndCriteria">
          <BreadcrumbNavigation>
            <Breadcrumb
              classes={(current) => ({
                ...current,
                root: `${current.root} CriteriaCollapseCard__breadcrumb`,
              })}
              label={metaTreeItem?.category || 'MISSING'}
            />
            <Breadcrumb
              classes={(current) => ({
                ...current,
                root: `${current.root} CriteriaCollapseCard__breadcrumb`,
              })}
              label={metaTreeItem?.attribute || 'MISSING'}
            />
          </BreadcrumbNavigation>
          <div
            className="CriteriaCollapseCard__criteriaLabel"
            onClick={() => editMode()}
          >
            {renderBreadcrumbValue()}
          </div>
        </div>
      </div>
      <IconButton onClick={() => handleDeleteGroup(criteria, criteriaIndex)}>
        <Icons.CrossIcon width={15} />
      </IconButton>
    </div>
  );
};

export default SelectedCriteriaBreadcrumb;
