import { facebookPermissionScopes } from './config';
import { AvailableIntegrationTypes } from '../configuration/types';
import { CustomErrorProtocol } from './errors';
import { Sync } from 'features/crusade/types';

export type FacebookPermissionScope = typeof facebookPermissionScopes;

export type IntegrationError = any;

export type IntegrationErrorAction = {
  labelId: string;
  handler: () => void;
  onlyFor?: CustomErrorProtocol[];
};

export type IntegrationErrorComponentProps = {
  integrationType: AvailableIntegrationTypes;
  actions?: IntegrationErrorAction[];
};

export enum ChannelSyncFrequency {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export interface CreateChannelRequest {
  channelName: string;
  channelType: AvailableIntegrationTypes;
  channelDescription?: string;
  channelProperties: {
    baseUrl: string;
    scheduler: { frequency: ChannelSyncFrequency };
    apiKeyLabel?: string;
    apiKey?: string;
    token?: string;
  };
}
