import { ChannelSyncFrequency } from 'features/integrations/types';
import { ReactNode } from 'react';

export type ChannelProperties = {
  ad_account_id?: string;
  login_ad_account_id?: string;
  baseUrl?: string;
  apiKeyLabel?: string;
  apiKey?: string;
  token?: string;
  scheduler?: {
    frequency: ChannelSyncFrequency;
  };
};

export type Channel = {
  adAccountId: string;
  channelId: string;
  channelType: string;
  channelDescription: string;
  channelName: string;
  createdBy: string;
  createdAt: string;
  channelProperties?: ChannelProperties;
  fieldsToExport?: string[];
};

export type ChannelsResponse = {
  data: Channel[] | null;
  message: string;
  statusCode: number;
};

export type DeletedChannel = {
  affected: number;
  raw: [];
};

export type DeleteChannelResponse = {
  data: DeletedChannel[] | null;
  message: string;
  statusCode: number;
};

export type EditChannelDescriptionParameter = {
  channelDescription: string;
};

export type EditChannelDescriptionResponse = {
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  channelId: string;
  channelName: string;
  channelType: string;
  channelDescription: string;
  oauth2ParamStoreKey: string;
  partnerId: string;
  channelProperties: string;
};

export enum AvailableIntegrationTypes {
  Facebook = 'facebookAds',
  Google = 'googleAds',
  Hubspot = 'hubspot',
  Braze = 'braze',
  MailChimp = 'mailchimp',
}

export type AvailableIntegration = {
  id: AvailableIntegrationTypes;
  titleId: string;
  icon: ReactNode;
};

export enum AvailableModalActionViews {
  BackToChoose = 'backToChoose',
  BackToConfigure = 'backToConfigure',
  GoToTest = 'goToTest',
  BackToConfigureAccount = 'backToConfigureAccount',
  ConnectToConfigure = 'connectToConfigure',
}

export enum AddChannelModalTabs {
  Configure = 'configure',
  Test = 'test',
  Choose = 'choose',
}

export enum AddChannelModalSubTabs {
  SelectAccount = 'SelectAccount',
}
