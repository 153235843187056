import { Routes } from '../config';
import faker from 'faker';
import moment from 'moment';

export const getRouteAddress = (routeName: Routes, params?: any) => {
  const allParams = Object.keys(params || {});
  return params !== undefined
    ? allParams.reduce(
        (acc: string, param: string) => acc.replace(`:${param}`, params[param]),
        routeName,
      )
    : routeName;
};

export const generateKey = () =>
  faker.datatype.number({ min: 1000000, max: 9999999 });

export const convertDateForDatePicker = (
  inputDate: string | undefined,
): Date | undefined => {
  const dateInStore = inputDate;

  if (typeof dateInStore !== 'string') return undefined;
  if (!dateInStore) return undefined;

  const year = parseInt(dateInStore.split('-')[0]);
  const month = parseInt(dateInStore.split('-')[1]) - 1;
  const date = parseInt(dateInStore.split('-')[2]);

  const dateAsDate = new Date(year, month, date);
  return dateAsDate;
};

export const DATE_TODAY = moment().format('dddd, MMMM Do');
export const CURRENT_TIME = moment().format('hh:mm');

export const numberInputValid = (input: string): boolean => {
  const inputHasAlphaChars = /[a-zA-Z]/.test(input);

  return (
    !isNaN(parseInt(`${input}`)) &&
    parseInt(`${input}`) > 0 &&
    !inputHasAlphaChars
  );
};

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
