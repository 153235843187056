import { DependencyContainer } from 'DependencyContainer';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import moment from 'moment';

import {
  CollapseCard,
  Heading,
  Icons,
  Button,
  CardHeaderVerticalActionStack,
  Status,
  Space,
  StaticCard,
  notify,
} from 'plume-ui';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import FormattedMessage from '../../utils/components/FormattedMessage';
import { SyncStateTypes, SyncStatusTypes } from 'features/syncs/types';

import mailchimpIcon from '../../assets/png/mailchimp.png';
import brazeIcon from '../../assets/png/braze.png';
import hubspotIcon from '../../assets/png/hubspot.png';

export type SyncCardProps = {
  title: string;
  state: SyncStateTypes;
  status: SyncStatusTypes;
  syncedTo: string;
  syncedToDate: string;
  rowsAdded: string | number;
  rowsDeleted: string | number;
  rowsUpdated: string | number;
  readOnly: boolean;
  syncFields?: string[];
  segmentName?: string;
  segmentId: string;
  syncId: string;
  refreshSyncData: () => void;
};

const SyncCard: FunctionComponent<SyncCardProps> = ({
  title,
  state,
  syncedTo,
  syncedToDate,
  rowsAdded,
  rowsDeleted,
  rowsUpdated,
  readOnly,
  segmentName,
  segmentId,
  syncId,
  refreshSyncData,
}) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const { syncsService } = new DependencyContainer();
  const partnerId = useRecoilValue(partnerIdAtom);
  const [syncState, setSyncState] = useState<'active' | 'paused'>();

  useEffect(() => {
    setSyncState(state);
  }, [state]);

  // Set custom colors for status
  const statusColorType = syncState === 'active' ? '#17E3AE' : '#FFC500';

  const syncIcon = useMemo(() => {
    switch (syncedTo) {
      case 'facebookAds':
        return <Icons.FacebookIcon className="SyncCard__header--syncIcon" />;
      case 'googleAds':
        return <Icons.GoogleIcon className="SyncCard__header--syncIcon" />;
      case 'mailchimp':
        return (
          <img
            src={mailchimpIcon}
            alt="Mailchimp icon"
            className="SyncCard__header--syncIcon"
          />
        );
      case 'braze':
        return (
          <img
            src={brazeIcon}
            alt="Braze icon"
            className="SyncCard__header--syncIcon"
          />
        );
      case 'hubspot':
        return (
          <img
            src={hubspotIcon}
            alt="Hubspot icon"
            className="SyncCard__header--syncIcon"
          />
        );
      default:
        return <span />;
    }
  }, [syncedTo]);

  const pauseAvailable = useMemo(
    () => ['facebookAds', 'googleAds'].includes(syncedTo),
    [syncedTo],
  );

  const cardHeaderActions = [
    <CardHeaderVerticalActionStack>
      <Status
        color={statusColorType}
        label={t(`generate.syncs.state.${syncState}`)}
      />
    </CardHeaderVerticalActionStack>,
  ];

  const deleteSync = async () => {
    try {
      const response = await syncsService.deleteSync(
        partnerId,
        segmentId,
        syncId,
      );
      if (response.data) {
        refreshSyncData();
        notify({
          title: t('syncs.deleteNotification'),
          body: '',
          type: 'success',
        });
      }
    } catch (err) {
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
    }
  };

  const getConfirmationModalBody = () => {
    const channel = t(`settings.channel.${syncedTo}`);
    return t('syncCard.deleteConfirmationMessage', { channel: channel });
  };

  function confirmDeleteSync(): void {
    showModal(
      MODAL_TYPES.CONFIRMATION_MODAL,
      {
        title: t('syncs.deleteModal.title'),
        body: getConfirmationModalBody(),
        onConfirm: () => {
          setTimeout(() => {
            deleteSync();
          }, 50);
        },
        isOpen: true,
        multipleLines: true,
      },
      MODAL_TYPES.CONFIRMATION_MODAL,
    );
  }

  const handleSyncStateChange = async (syncState: 'active' | 'paused') => {
    const payload = {
      schedule: '',
      fieldsToSync: '',
      state: syncState,
    };

    try {
      await syncsService.updateSync(partnerId, segmentId, syncId, payload);
      setSyncState(syncState);
    } catch (err) {
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
    }
  };

  const renderDateAndTime = () => {
    if (
      syncedToDate !== null &&
      syncedToDate !== undefined &&
      syncedToDate !== ''
    ) {
      const syncedDate = moment.utc(syncedToDate).local().format('L');
      const syncedTime = moment.utc(syncedToDate).local().format('hh:mm a');

      return t('syncCard.timeSync', {
        syncedDate,
        syncedTime,
        interpolation: { escapeValue: false },
      });
    } else {
      return t('NA');
    }
  };

  const renderCardBody = () => {
    return (
      <>
        {readOnly && (
          <div className="SyncCard__readonly-audienceSegment">
            <div>
              <FormattedMessage id="syncCard.audienceSegment" />
            </div>
            <div>{segmentName}</div>
          </div>
        )}
        <table className="SyncCard__table">
          <tbody className="SyncCard__table-body">
            <tr>
              <th className="SyncCard__table-cell">{`${t(
                'syncCard.syncedTo',
              )} ${t(`settings.channel.${syncedTo}`)}`}</th>
              <th className="SyncCard__table-cell">
                <FormattedMessage id="syncCard.rowsAdded" />:
              </th>
              <th className="SyncCard__table-cell">
                <FormattedMessage id="syncCard.rowsDeleted" />:
              </th>
              <th className="SyncCard__table-cell">
                <FormattedMessage id="syncCard.rowsUpdated" />:
              </th>
            </tr>
            <tr>
              <td>{renderDateAndTime()}</td>
              <td>{rowsAdded}</td>
              <td>{rowsDeleted}</td>
              <td>{rowsUpdated}</td>
            </tr>
          </tbody>
        </table>
        <Space size="s" />
        <div className="SyncCard__actions">
          {pauseAvailable && (
            <>
              {syncState === 'active' && (
                <Button
                  styleVariant="action"
                  icon={<Icons.PauseIcon style={{ color: '#FFC500' }} />}
                  onClick={() => handleSyncStateChange('paused')}
                >
                  <FormattedMessage id="syncCard.pauseSync" />
                </Button>
              )}
              {syncState === 'paused' && (
                <Button
                  styleVariant="action"
                  icon={<Icons.PlayIcon style={{ color: '#17E3AE' }} />}
                  onClick={() => handleSyncStateChange('active')}
                >
                  <FormattedMessage id="syncCard.unPauseSync" />
                </Button>
              )}
            </>
          )}
          <Button
            styleVariant="action"
            icon={<Icons.CrossIcon style={{ color: 'red' }} />}
            onClick={() => confirmDeleteSync()}
          >
            <FormattedMessage id="syncCard.deleteSync" />
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      {readOnly ? (
        <StaticCard
          title={
            <Heading size="xl">
              {syncIcon}
              {title}
            </Heading>
          }
          actions={cardHeaderActions}
        >
          {renderCardBody()}
        </StaticCard>
      ) : (
        <CollapseCard
          key={syncId}
          open={false}
          title={
            <Heading size="xl">
              {syncIcon} {title}
            </Heading>
          }
          actions={cardHeaderActions}
        >
          {renderCardBody()}
        </CollapseCard>
      )}
    </>
  );
};

export default SyncCard;
