import React, { useState, createContext, useContext, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { selectedSegmentAtom } from 'store/state/audienceFlowState';
import { MODAL_COMPONENTS } from './ModalComponents';

type GlobalModalContextType = {
  showModal: (modalType: string, modalProps?: any, id?: string) => void;
  hideModal: (id?: string) => void;
  closeAllModals: () => void;
  switchModal: (
    closingModalId: string,
    openModalType: string,
    openModalProps: any,
    openModalId: string,
  ) => void;
  store: ModalStore;
};

type ModalStore = {
  [key: string]: {
    modalType: string;
    modalProps: Record<string, unknown>;
  };
};

const initialState: GlobalModalContextType = {
  showModal: () => {},
  hideModal: () => {},
  closeAllModals: () => {},
  switchModal: () => {},
  store: {},
};

export const GlobalModalContext = createContext(initialState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [store, setStore] = useState<ModalStore>({});
  const setSelectedSegment = useSetRecoilState(selectedSegmentAtom);

  const showModal = useCallback(
    (modalType: string, modalProps: any, id: string = 'default_id') => {
      setStore((prevStore) => ({
        ...prevStore,
        [id]: {
          modalType,
          modalProps,
        },
      }));
    },
    [],
  );

  const hideModal = useCallback((id: string = 'default_id') => {
    setStore((prevStore) => {
      const newStore = { ...prevStore };
      delete newStore[id];
      return newStore;
    });
  }, []);

  const switchModal = useCallback(
    (
      closingModalId: string,
      openModalType: string,
      openModalProps: any,
      openModalId: string,
    ) => {
      setStore((prevStore) => {
        const newStore = { ...prevStore };
        delete newStore[closingModalId];
        return {
          ...newStore,
          [openModalId]: {
            modalType: openModalType,
            modalProps: openModalProps,
          },
        };
      });
    },
    [],
  );

  const closeAllModals = useCallback(() => {
    setStore({});
    if (store.SEGMENTS_MODAL || store.SPLIT_SEGMENTS_MODAL) {
      setSelectedSegment(undefined);
    }
  }, [store, setSelectedSegment]);

  const renderComponent = () => {
    return (
      <>
        {Object.entries(store).map(([id, { modalType, modalProps }]) => {
          const ModalComponent = MODAL_COMPONENTS[modalType];
          if (!modalType || !ModalComponent) {
            return null;
          }
          return <ModalComponent id={id} {...modalProps} key={id} />;
        })}
      </>
    );
  };

  return (
    <GlobalModalContext.Provider
      value={{ store, showModal, hideModal, closeAllModals, switchModal }}
    >
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
