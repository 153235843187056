import React, { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'plume-ui';
import { useRecoilValue } from 'recoil';
import { Maybe } from 'types';
import { AxiosError } from 'axios';

import FormattedMessage from '../../../../utils/components/FormattedMessage';
import SyncTestResult from '../../../../components/CreateSyncModal/SyncTestResult';
import { introspectAtom } from '../../../../store/state/introspect';
import { ConfigChannelModalContext } from './AddChannelModalContext';
import { DependencyContainer } from '../../../../DependencyContainer';

import { AvailableIntegrationTypes } from '../../../configuration/types';
import { TestChannelResponse } from '../../../configuration/integrationsState';
import {
  mapSelectedConnectionToName,
  mapSelectedIntegrationToConfigureSubtitle,
} from './AddChannelConfigure';

const { integrationsService } = new DependencyContainer();

const mapStatuses = {
  success: 'success',
  failure: 'error',
  error: 'error',
};

export const AddChannelTest: FunctionComponent = () => {
  const { t } = useTranslation();
  const context = useContext(ConfigChannelModalContext);
  const introspect = useRecoilValue(introspectAtom);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Maybe<AxiosError>>();
  const [testResult, setTestResult] = useState<TestChannelResponse>();

  const onStartTest = async () => {
    if (
      !context.selectedAccountType ||
      !introspect?.partnerId ||
      (!context.newChannelResponse && !context.adConnectedAccount)
    ) {
      return;
    }
    setLoading(true);
    try {
      const result = await integrationsService.testChannel(
        context.selectedAccountType,
        context.newChannelResponse?.channelId ||
          context.adConnectedAccount?.channel_id ||
          '',
        introspect.partnerId,
      );
      setTestResult(result);
      setError(undefined);
    } catch (error) {
      setTestResult(undefined);
      setError(error as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const getStatus = () => {
    if (error) {
      return 'error';
    }
    if (!testResult?.status || loading) {
      return 'loading';
    }
    return mapStatuses[testResult.status];
  };

  const isErrorRequestTimedOut = () => {
    return (
      (error && error?.response === undefined) ||
      error?.response?.status === 504
    );
  };

  const getMessage = () => {
    if (isErrorRequestTimedOut()) {
      return t('settings.errors.requestTimedOut.descriptionForTesting');
    }
    if (error) {
      return t('somethingWentWrong');
    }
    return testResult?.error_message;
  };

  const buttonCopy = loading ? 'loading' : 'settings.channel.runTestButton';

  const hasSyncTestResults = Boolean(testResult || error);

  return (
    <div>
      <p className="AddChannelModal__testTitle">
        <FormattedMessage id="settings.channel.testTitle" />
      </p>
      <p className="AddChannelModal__testSubtitle">
        {context.selectedAccountType &&
          t('settings.channel.testSubtitle', {
            platform: mapSelectedConnectionToName[context.selectedAccountType],
          })}
      </p>
      <div className="AddChannelModal__testButton">
        <Button onClick={() => onStartTest()}>
          <FormattedMessage id={buttonCopy} />
        </Button>
      </div>

      <p className="AddChannelModal__testingSync">
        <FormattedMessage id="settings.channel.testingConnection" />
      </p>
      {hasSyncTestResults && (
        <SyncTestResult
          status={getStatus()}
          syncTestResult={{
            result: getStatus(),
            message: getMessage(),
          }}
        />
      )}
    </div>
  );
};

export default AddChannelTest;
