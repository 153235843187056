import React, { FunctionComponent, useContext } from 'react';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { Box } from 'plume-ui';
import { AvailableIntegration } from '../../types';
import { ConfigChannelModalContext } from './AddChannelModalContext';
import { availableIntegrations } from '../../config';

export const AddChannelChoose: FunctionComponent = () => {
  const context = useContext(ConfigChannelModalContext);
  return (
    <Box>
      {availableIntegrations.map((integration: AvailableIntegration) => (
        <div
          key={integration.id}
          onClick={() => context.handleIntegrationChange(integration)}
          className="AddChannelModal__adsContainer"
        >
          <div className="AddChannelModal__adsIcon">{integration.icon}</div>
          <p>
            <FormattedMessage id={integration.titleId} />
          </p>
        </div>
      ))}
    </Box>
  );
};

export default AddChannelChoose;
