import { CampaignStatus } from 'features/crusade/types';
import { APP_URL, Routes } from './config';
import {
  getBackendCrusadeUrl,
  getFacebookClientId,
  getFacebookLoginUrl,
  getGoogleClientId,
} from './environment';
import { AvailableIntegrationTypes } from './features/configuration/types';
import {
  ChannelSyncFrequency,
  FacebookPermissionScope,
} from './features/integrations/types';

type Urls = Record<string, CallableFunction>;

export const LoginUrls: Urls = {
  getRedirectUriForOkta: () => APP_URL + Routes.LoginCallback,
};

export const HarvestApiUrls: Urls = {
  getApiVersionUrl: () => `/generate/version`,

  getAudienceTree: (partnerId: string) =>
    `/generate/partners/${partnerId}/segmentTree?includeSyncs=true&includeCampaigns=true`,

  getMetadataTree: () => `/generate/locations/metadataTree `,

  getSegmentSize: (partnerId: string, segmentId: string) =>
    `generate/partners/${partnerId}/segmentTree/${segmentId}/audienceSizes`,

  getDynamicMetadataTree: (partnerId: string, segmentNodeId: string) =>
    `generate/partners/${partnerId}/segmentNode/${segmentNodeId}/dynamicMetadataTree`,

  addSegmentNode: (partnerId: string) =>
    `generate/partners/${partnerId}/segmentNode`,

  updateSegmentNode: (partnerId: string, segmentId: string) =>
    `generate/partners/${partnerId}/segmentNode/${segmentId}`,

  deleteSegmentNode: (partnerId: string, segmentId: string) =>
    `generate/partners/${partnerId}/segmentNode/${segmentId}`,

  getSegmentsPreview: (partnerId: string, segmentId: string) =>
    `generate/partners/${partnerId}/segmentNode/${segmentId}/preview`,

  getChannels: (partnerId: string) => `generate/partners/${partnerId}/channels`,

  editChannelDescription: (partnerId: string, channelId: string) =>
    `generate/partners/${partnerId}/channels/${channelId}`,

  editChannel: (partnerId: string, channelId: string) =>
    `generate/partners/${partnerId}/channels/${channelId}`,

  updateChannelFrequency: (partnerId: string, channelId: string) =>
    `/generate/partners/${partnerId}/channel/${channelId}/scheduler`,

  deleteChannel: (partnerId: string, channelId: string) =>
    `generate/partners/${partnerId}/channels/${channelId}`,

  getSyncs: (partnerId: string) => `generate/partners/${partnerId}/syncs`,

  addSync: (partnerId: string, segmentId: string) =>
    `generate/partners/${partnerId}/segmentNode/${segmentId}/sync`,

  updateSync: (partnerId: string, segmentId: string, syncId: string) =>
    `generate/partners/${partnerId}/segmentNode/${segmentId}/sync/${syncId}`,

  getMetadataAllowedValues: (partnerId: string, columnName: string) =>
    `generate/partners/${partnerId}/metadata/attribute/allowedValues/${columnName}`,

  deleteSync: (partnerId: string, segmentNodeId: string, syncId: string) =>
    `generate/partners/${partnerId}/segmentNode/${segmentNodeId}/sync/${syncId}`,

  addSplitNode: (partnerId: string, segmentNodeId: string) =>
    `generate/partners/${partnerId}/segmentNode/${segmentNodeId}/splitNode`,

  updateSplitNode: (partnerId: string, segmentNodeId: string) =>
    `generate/partners/${partnerId}/segmentNode/${segmentNodeId}/splitNode`,

  getSplitNode: (partnerId: string, segmentNodeId: string) =>
    `generate/partners/${partnerId}/segmentNode/${segmentNodeId}/splitNode`,

  deleteSplitNode: (partnerId: string, segmentNodeId: string) =>
    `generate/partners/${partnerId}/segmentNode/${segmentNodeId}/splitNode`,

  getSegmentGrowthHistory: (partnerId: string) =>
    `generate/partners/${partnerId}/analytics/segments`,

  createNewConnection: (partnerId: string) =>
    `generate/partners/${partnerId}/channels`,

  editConnection: (channelId: string, partnerId: string) =>
    `generate/partners/${partnerId}/channels/${channelId}`,

  introspect: () => 'generate/auth/introspect',
};

export const CrusadeApiUrls: Urls = {
  getApiVersionUrl: () => `/crusade/version`,

  getCrusadeJourneys: (partnerId: string) =>
    getBackendCrusadeUrl() + `/crusade/partners/${partnerId}/journeys`,

  getCrusadeJourneysAndCampaigns: (partnerId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/journeys?include=campaigns`,

  getCrusadeCampaignEmailData: (partnerId: string, campaignId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/campaigns/${campaignId}/message`,

  sendPreviewTestMessage: (partnerId: string, campaignId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/campaigns/${campaignId}/sendPreviewTestEmail`,

  sendPreviewTestNotification: (partnerId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/campaigns/previewTestNotificationWithEmail`,

  getPatchDeleteCrusadeCampaignId: (partnerId: string, campaignId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/campaigns/${campaignId}`,

  getTargetAudience: (partnerId: string) =>
    getBackendCrusadeUrl() + `/crusade/partners/${partnerId}/segments`,

  createJourney: (partnerId: string) =>
    getBackendCrusadeUrl() + `/crusade/partners/${partnerId}/journeys`,

  createPushNotification: (partnerId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/notificationCampaigns`,

  editPushNotification: (partnerId: string, campaignId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/campaigns/${campaignId}`,

  launchPushNotification: (
    partnerId: string,
    campaignId: string,
    status: CampaignStatus,
  ) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/campaigns/status/${campaignId}/${status}`,

  preCalculateGlobalFilters: (partnerId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/preCalculateGlobalFilter`,

  getIpWarmupPreview: (partnerId: string) =>
    getBackendCrusadeUrl() + `/crusade/partners/${partnerId}/warmup/preview`,

  getDuplicateCrusadeCampaign: (partnerId: string, campaignId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/campaignInstances/${campaignId}/clone`,

  getCampaignConfigEmailDomainList: (partnerId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/email/configuration/domain-ip-pool/list`,

  getCampaignEmailConfiguration: (partnerId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/email/configuration/all`,

  editCampaignEmailConfiguration: (partnerId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/email/configuration/all`,

  validateCampaignTestEmail: (partnerId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/campaigns/validateEmailsPresent?type=email`,

  getCampaignTemplates: (partnerId: string) =>
    getBackendCrusadeUrl() + `/crusade/partners/${partnerId}/templates`,

  createDraftCampaignFromTemplate: (partnerId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/campaigns/fromTemplate`,

  getCampaignGrowthHistory: (partnerId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/campaigns?status=Active,Completed,Paused`,

  getIndividualCampaignHistory: (
    partnerId: string,
    campaignId: string,
    daysAgo: number,
  ) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/analytics/campaigns/${campaignId}?daysAgo=${daysAgo}`,

  getNps30DayHistory: (partnerId: string, campaignId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/analytics/campaigns/${campaignId}/nps?daysAgo=30`,

  getNpsAllTimeHistory: (partnerId: string, campaignId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/analytics/campaigns/${campaignId}/nps?allTime=true`,

  getCampaignObjectives: (partnerId: string) =>
    getBackendCrusadeUrl() + `/crusade/partners/${partnerId}/objectives`,

  createCampaignObjective: (partnerId: string) =>
    getBackendCrusadeUrl() + `/crusade/partners/${partnerId}/objectives`,

  updateCampaignObjective: (partnerId: string, objectiveId: string) =>
    getBackendCrusadeUrl() +
    `/crusade/partners/${partnerId}/objectives/${objectiveId}`,
};

export const OAuth2ApiUrls: Record<string, any> = {
  getConnectAccountUrl: (
    accountType: AvailableIntegrationTypes,
    accountId: string,
    partnerId: string,
  ) => {
    return `/oauth2api/${accountType}/ad-accounts/${accountId}/connect?partner_id=${partnerId}`;
  },
  getTestChannelUrl: (
    accountType: AvailableIntegrationTypes,
    channelId: string,
    partnerId: string,
  ) => {
    return `/oauth2api/${accountType}/utils/${channelId}/test-channel/?partner_id=${partnerId}`;
  },
  getExchangeAccountTokenUrl: (
    accountType: AvailableIntegrationTypes,
    token: string,
    partnerId: string,
    redirectUri: string,
  ) => {
    const codeFieldName =
      accountType === AvailableIntegrationTypes.Facebook
        ? 'fb_exchange_token'
        : 'code';
    return `/oauth2api/${accountType}/oauth2/authorization_code?${codeFieldName}=${token}&partner_id=${partnerId}&redirect_uri=${redirectUri}`;
  },
  getReAuthChannelUrl: (
    channelId: string,
    partnerId: string,
    code: string,
    redirectUri: string,
  ) => {
    return `/oauth2api/channels/${channelId}?partner_id=${partnerId}&code=${code}&redirect_uri=${redirectUri}`;
  },
  getAdAccountsUrl: (
    accountType: AvailableIntegrationTypes,
    partnerId: string,
  ) => {
    return `/oauth2api/${accountType}/ad-accounts?partner_id=${partnerId}`;
  },
};

export type AuthorizationUrls = Record<
  string,
  (redirectUri: string, scope: any, state?: any) => string
>;
export const ThirdPartyUrls: AuthorizationUrls = {
  getFacebookAuthorizationUrl: (
    redirectUri: string,
    scope: FacebookPermissionScope,
    state?: any,
  ) =>
    `${getFacebookLoginUrl()}/dialog/oauth?client_id=${getFacebookClientId()}&redirect_uri=${redirectUri}&state=${
      state ? JSON.stringify(state) : '{}'
    }&response_type=code%20token&scope=${scope.join()}`,
  getGoogleAuthorizationUrl: (redirectUri: string, scope: any, state?: any) => {
    const scopeString = scope.join(' ');
    return `https://accounts.google.com/o/oauth2/v2/auth?client_id=${getGoogleClientId()}&redirect_uri=${redirectUri}&response_type=code&scope=${scopeString}&state=${state}&access_type=offline&prompt=consent`;
  },
};
