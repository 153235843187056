import { Maybe } from 'types';
import { DependencyContainer } from '../../../DependencyContainer';
import { Channel, EditChannelDescriptionParameter } from '../types';
import { ChannelSyncFrequency } from 'features/integrations/types';

export default class ChannelsService {
  constructor(private readonly factory: DependencyContainer) {}

  async getChannels(partnerId: Maybe<string>): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.channelsClient.getChannels(partnerId);
  }

  async editChannelDescription(
    partnerId: Maybe<string>,
    channelId: string,
    parameters: EditChannelDescriptionParameter,
  ): Promise<any> {
    if (partnerId === undefined) {
      return;
    }
    return await this.factory.channelsClient.editChannelDescription(
      partnerId,
      channelId,
      parameters,
    );
  }

  async deleteChannel(
    partnerId: Maybe<string>,
    channelId: string,
  ): Promise<any> {
    if (partnerId === undefined) {
      return;
    }
    return await this.factory.channelsClient.deleteChannel(
      partnerId,
      channelId,
    );
  }

  async editChannelSyncFrequency(
    partnerId: Maybe<string>,
    channelId: string,
    frequency: ChannelSyncFrequency,
  ): Promise<any> {
    if (partnerId === undefined) {
      return;
    }
    return await this.factory.channelsClient.editChannelSyncFrequency(
      partnerId,
      channelId,
      frequency,
    );
  }
}
