import React, { FunctionComponent } from 'react';
import { IntegrationErrorComponentProps } from '../../../integrations/types';
import { AvailableIntegrationTypes } from '../../../configuration/types';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import ErrorActionsRenderer from './ErrorActionsRenderer';
import { ErrorPermissionsNotGranted } from '../../errors';

export const ErrorPermissionsNotGrantedComponent: FunctionComponent<IntegrationErrorComponentProps> = ({
  actions,
  integrationType,
}) => {
  const mapDescriptionCopyIdToIntegrationType: Partial<Record<
    AvailableIntegrationTypes,
    string
  >> = {
    [AvailableIntegrationTypes.Facebook]:
      'settings.errors.permissionsNotGranted.descriptionForFacebook',
    [AvailableIntegrationTypes.Google]:
      'settings.errors.permissionsNotGranted.descriptionForGoogle',
  };
  const descriptionId = mapDescriptionCopyIdToIntegrationType[integrationType];
  return (
    <div className="IntegrationsContainer__error">
      <h1>
        <FormattedMessage id="settings.errors.permissionsNotGranted.title" />
      </h1>
      <p>
        <FormattedMessage id={descriptionId || 'unknown'} />
      </p>
      <ErrorActionsRenderer
        actions={actions}
        error={ErrorPermissionsNotGranted}
      />
    </div>
  );
};

export default ErrorPermissionsNotGrantedComponent;
