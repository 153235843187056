import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';

export type UseWithConfirmationParams = {
  onConfirm: () => void;
  onCancel?: () => void;
  title?: string;
  body?: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  maxWidth?: number;
};

const useWithConfirmation = (): ((
  params: UseWithConfirmationParams,
) => void) => {
  const { showModal } = useGlobalModalContext();

  return (params) => {
    showModal(
      MODAL_TYPES.CONFIRMATION_MODAL,
      {
        title: params.title,
        body: params.body,
        confirmButtonLabel: params.confirmButtonLabel,
        cancelButtonLabel: params.cancelButtonLabel,
        onConfirm: params.onConfirm,
        onCancel: params.onCancel,
        isOpen: true,
        maxWidth: params.maxWidth,
      },
      MODAL_TYPES.CONFIRMATION_MODAL,
    );
  };
};

export default useWithConfirmation;
