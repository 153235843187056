import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import moment from 'moment';
import {
  Button,
  Chip,
  Divider,
  Dropdown,
  DropdownSelectableItem,
  Icons,
  InputField,
  Logo,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  notify,
  PendingContent,
  Spinner,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, { FormEvent, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { brandingCompanyNameAtom } from 'store/state/configurationState';
import FormattedMessage from 'utils/components/FormattedMessage';
import { NotificationPreviewDropdown } from 'utils/fixtures/NotificationPreview.fixture';
import { DependencyContainer } from '../../../DependencyContainer';
import {
  editingCampaignAtom,
  editingCampaignEmailData,
} from '../../../store/state/campaignState';
import { CURRENT_TIME, DATE_TODAY, generateKey } from '../../../utils/helpers';
import Iframe from '../../crusade/util/Iframe';
import { useCampaignEmailData } from '../hooks/useCampaignEmailData';
import { CampaignPushNotificationCardValues, CampaignType } from '../types';
import {
  bluetoothIcon,
  dataIcon,
  flightIcon,
  locationIcon,
  volumeIcon,
  wifiIcon,
} from '../util/AndroidIcons';

export type PreviewAndTestModalProps = {
  mode: string;
  pushCampaign?: CampaignPushNotificationCardValues;
};

export const PreviewAndTestModal: FunctionComponent<PreviewAndTestModalProps> = ({
  mode,
  pushCampaign,
}) => {
  const { t } = useTranslation();
  const editingCampaign = useRecoilValue(editingCampaignAtom);
  const { loading, error } = useCampaignEmailData(
    editingCampaign?.campaignId,
    editingCampaign?.campaignType as CampaignType,
  );
  const emailData = useRecoilValue(editingCampaignEmailData);
  const [id, setId] = useState('');
  const [idList, setIdList] = useState<string[]>([]);
  const { crusadeJourneyService } = new DependencyContainer();
  const partnerId = useRecoilValue(partnerIdAtom);
  const [dropdownData] = useState(NotificationPreviewDropdown());
  const [selectedDevice, setSelectedDevice] = useState(
    `${t('crusade.campaigns.iPhoneLabel')}`,
  );
  const [selectedScreen, setSelectedScreen] = useState(
    `${t('crusade.campaigns.lockScreenLabel')}`,
  );
  const [validatingEmail, setValidatingEmail] = useState(false);
  const deviceList = dropdownData?.find(
    (item: any) => item.title === t('crusade.campaigns.device'),
  );
  const screenList = dropdownData?.find(
    (item: any) => item.title === t('crusade.campaigns.notificationState'),
  );
  const { hideModal } = useGlobalModalContext();
  const companyName = useRecoilValue(brandingCompanyNameAtom);
  const [sendingTest, setSendingTest] = useState(false);

  const scrollContainer = {
    marginRight: '2px',
    maxHeight: '628px',
    padding: '32px',
  };

  const onDeviceSelect = (value: string): void => {
    setSelectedDevice(value);
  };

  const onScreenSelect = (value: string): void => {
    setSelectedScreen(value);
  };

  const handleInput = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const currentIdList: string[] = [...idList];

    const isCommaSeparated = `${id}`.includes(',');

    if (!isCommaSeparated) {
      currentIdList.push(id);
    } else {
      id.split(',').forEach((id) => {
        currentIdList.push(id.trim());
      });
    }

    setValidatingEmail(true);

    let invalidEmails: string[] = [];
    let validEmails: string[] = [];
    await crusadeJourneyService
      .validateCampaignTestEmail(partnerId, currentIdList)
      .then((response) => {
        const invalidEmailsList = response.data.missingEmailToExternalIds;

        currentIdList.forEach((id) => {
          if (invalidEmailsList.includes(id)) {
            invalidEmails.push(id);
          } else {
            validEmails.push(id);
          }
        });

        if (invalidEmails.length > 0) {
          notify({
            id: 'emailInvalid',
            title: t('crusade.campaigns.testEmailInvalid'),
            body: t('crusade.campaigns.testEmailInvalidMessage'),
            type: 'error',
            showDismiss: true,
            mustDismiss: true,
          });
        }

        setIdList(validEmails);
        setId('');
        setValidatingEmail(false);
      })
      .catch(() => {
        setValidatingEmail(false);
      });
  };

  const handleRemoveId = (idToRemove: string) => {
    const currentIdList: string[] = [...idList];
    const filtered = currentIdList.filter((id) => idToRemove !== id);
    setIdList(filtered);
  };

  const handleSendTest = async () => {
    if (mode === 'emailPreview') {
      setSendingTest(true);
      try {
        await crusadeJourneyService.sendPreviewTestMessage(
          partnerId,
          emailData.campaignId,
          idList,
        );

        notify({
          title: t('success'),
          body: t('crusade.campaigns.testEmailSent'),
          type: 'success',
        });
      } catch (error) {
        notify({
          title: t('error'),
          body: t('somethingWentWrong'),
          type: 'error',
        });
      }
      setSendingTest(false);
    } else {
      setSendingTest(true);
      if (partnerId) {
        try {
          await crusadeJourneyService.sendPreviewTestNotification(
            partnerId,
            idList,
            pushCampaign,
          );

          notify({
            title: t('success'),
            body: t('crusade.campaigns.testPushNotificationSent'),
            type: 'success',
          });
        } catch (error) {
          if (pushCampaign?.title === '' || pushCampaign?.messageBody === '') {
            notify({
              title: t('error'),
              body: t('crusade.campaigns.missingPushFieldsError'),
              type: 'error',
            });
          } else {
            notify({
              title: t('error'),
              body: t('somethingWentWrong'),
              type: 'error',
            });
          }
        }
      }
      setSendingTest(false);
    }
  };

  const handleModalClose = () => {
    setId('');
    setIdList([]);
    hideModal(MODAL_TYPES.PREVIEW_AND_TEST_MODAL);
  };

  const renderAndroidNotification = () => {
    return (
      <div className="PreviewAndTestModal__androidNotificationContainer">
        <div className="PreviewAndTestModal__notificationBody">
          <div>
            <div className="PreviewAndTestModal__notificationTitleWrapper">
              <div className="PreviewAndTestModal__logoContainer">
                <Logo />
              </div>
              <p className="PreviewAndTestModal__notificationTitle">{`${t(
                'plume',
              )} • ${t('crusade.campaigns.androidTimeLabel')}`}</p>
            </div>
            <p className="PreviewAndTestModal__appName">
              {(pushCampaign && pushCampaign.title) ||
                companyName ||
                t('crusade.campaigns.title')}
            </p>
            <p className="PreviewAndTestModal__notificationTitle">
              {(pushCampaign && pushCampaign.messageBody) ||
                t('crusade.campaigns.message')}
            </p>
          </div>
          <div className="PreviewAndTestModal__androidlogoContainer">
            <div className="PreviewAndTestModal__mainAndroidLogo">
              <Logo />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderIPhoneNotification = () => {
    return (
      <div className="PreviewAndTestModal__notificationContainer">
        <div className="PreviewAndTestModal__logoContainer">
          <Logo />
        </div>
        <div>
          <p className="PreviewAndTestModal__appName">
            {pushCampaign?.title || companyName || t('crusade.campaigns.title')}
          </p>
          <p className="PreviewAndTestModal__notificationTitle">
            {(pushCampaign && pushCampaign.messageBody) ||
              t('crusade.campaigns.message')}
          </p>
        </div>
        <p className="PreviewAndTestModal__time">
          <FormattedMessage id="crusade.campaigns.nowLabel" />
        </p>
      </div>
    );
  };

  const renderIPhoneLockScreen = () => {
    return (
      <div className="PreviewAndTestModal__deviceWrapper">
        <div className="PreviewAndTestModal__device">
          <div className="PreviewAndTestModal__iconContainer">
            <Icons.WifiIcon className="PreviewAndTestModal__icon" />
            <Icons.GraphBarIcon className="PreviewAndTestModal__icon" />
          </div>
          <div className="PreviewAndTestModal__personeIconContainer">
            <Icons.PersonStrokeIcon className="PreviewAndTestModal__personeIcon" />
          </div>
          <p className="PreviewAndTestModal__screenTime">{CURRENT_TIME}</p>
          <p className="PreviewAndTestModal__screenDate">{DATE_TODAY}</p>
          {renderIPhoneNotification()}
        </div>
        <div className="PreviewAndTestModal__navigation-btn"></div>
      </div>
    );
  };

  const renderIPhoneActiveScreen = () => {
    return (
      <div className="PreviewAndTestModal__deviceWrapper">
        <div className="PreviewAndTestModal__device">
          <div className="PreviewAndTestModal__appWrapper">
            <div className="PreviewAndTestModal__appIcon"></div>
            <div className="PreviewAndTestModal__appIcon"></div>
            <div className="PreviewAndTestModal__appIcon"></div>
            <div className="PreviewAndTestModal__appIcon"></div>
            <div className="PreviewAndTestModal__appIcon"></div>
          </div>
          <div className="PreviewAndTestModal__activeNotificationWrapper">
            {renderIPhoneNotification()}
          </div>
        </div>
        <div className="PreviewAndTestModal__navigation-btn"></div>
      </div>
    );
  };

  const renderIPhoneExpandedScreen = () => {
    return (
      <div className="PreviewAndTestModal__deviceWrapper">
        <div className="PreviewAndTestModal__device">
          <div className="PreviewAndTestModal__expandedNotificationWrapper">
            {renderIPhoneNotification()}
          </div>
        </div>
        <div className="PreviewAndTestModal__navigation-btn"></div>
      </div>
    );
  };

  const renderIPhoneScreen = () => {
    if (selectedScreen === t('crusade.campaigns.lockScreenLabel')) {
      return renderIPhoneLockScreen();
    } else if (selectedScreen === t('crusade.campaigns.expandedScreenLabel')) {
      return renderIPhoneExpandedScreen();
    } else {
      return renderIPhoneActiveScreen();
    }
  };

  const renderAndroidScreen = () => {
    return (
      <div className="PreviewAndTestModal__androidDevice">
        <p className="PreviewAndTestModal__timeLable">
          {moment(Date()).format('LT')}
        </p>
        <div className="PreviewAndTestModal__sliderWrapper">
          <p className="PreviewAndTestModal__dateLable">
            {moment(Date()).format('dddd, MMM Do')}
          </p>
          <div className="PreviewAndTestModal__controlWrapper">
            <div>{wifiIcon()}</div>
            <div>{bluetoothIcon()}</div>
            <div>{flightIcon()}</div>
            <div>{locationIcon()}</div>
            <div>{volumeIcon()}</div>
            <div>{dataIcon()}</div>
          </div>
        </div>
        <p className="PreviewAndTestModal__notificationLabel">
          <FormattedMessage id="crusade.campaigns.notificationLabel" />
        </p>
        {renderAndroidNotification()}
      </div>
    );
  };

  return (
    <Modal
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} PreviewAndTestModal`,
      })}
      isOpen={true}
      onRequestClose={handleModalClose}
    >
      <ModalHeader title={t('crusade.campaigns.emailPreviewAndTest')} />
      <ModalBody>
        <div className="PreviewAndTestModal__body">
          <div className="PreviewAndTestModal__bodyTitle">
            {t('crusade.campaigns.testRecipients')}
          </div>
          <div
            className={`${
              mode === 'emailPreview'
                ? 'PreviewAndTestModal__bodyContentEmail'
                : 'PreviewAndTestModal__bodyContent'
            }`}
          >
            <div
              className={`${
                mode === 'emailPreview'
                  ? 'PreviewAndTestModal__previewInfoDivEmail'
                  : 'PreviewAndTestModal__previewInfoDiv'
              }`}
            >
              <PendingContent loading={validatingEmail} loader={Spinner}>
                <div className="PreviewAndTestModal__InputEmail">
                  <form
                    className="PreviewAndTestModal__form"
                    onSubmit={handleInput}
                  >
                    <div className="EditCard__inputWrapper">
                      <InputField
                        classes={(current) => ({
                          ...current,
                          input: `${current.input} PreviewAndTestModal__sendTestInput`,
                        })}
                        type="email"
                        name="recipientEmail"
                        label={t(
                          'crusade.campaigns.emailPreviewEmailEntryFieldLabel',
                        )}
                        onInput={(e) =>
                          setId((e.target as HTMLInputElement).value)
                        }
                        value={id}
                        multiple
                      />
                    </div>
                    {id && (
                      <Button
                        styleVariant="quaternary"
                        onClick={(e: any) => handleInput(e)}
                      >
                        <Icons.RightArrowIcon className="PreviewAndTestModal__addButton" />
                      </Button>
                    )}
                  </form>
                </div>
              </PendingContent>
              <Divider orientation="vertical" />
              <div className="PreviewAndTestModal__chipContainer">
                {idList.map((id) => {
                  return (
                    <Chip
                      classes={(current) => ({
                        ...current,
                        root: `${current.root} PreviewAndTestModal__chip`,
                      })}
                      key={generateKey()}
                      type="mini"
                      removable={true}
                      color="solid"
                      onClick={() => handleRemoveId(id)}
                    >
                      {id}
                    </Chip>
                  );
                })}
              </div>
              <Divider orientation="vertical" />
              <div
                className={`${
                  mode === 'emailPreview'
                    ? 'PreviewAndTestModal__emailSendTestBtnWrapper'
                    : 'PreviewAndTestModal__sendTestBtnWrapper'
                }`}
              >
                <Button
                  styleVariant="superprimary"
                  onClick={handleSendTest}
                  disabled={!idList?.length || sendingTest}
                >
                  {t('crusade.campaigns.sendTest')}
                </Button>
              </div>
            </div>
            {mode === 'emailPreview' ? (
              <>
                <div className="PreviewAndTestModal__bodyTitle">
                  {t('crusade.campaigns.emailPreview')}
                </div>
                {emailData && (
                  <div className="PreviewAndTestModal__emailPreviewDiv">
                    <div className="PreviewAndTestModal__previewDivFrom">
                      {t('crusade.campaigns.emailFrom', {
                        email: emailData.from,
                      })}
                    </div>
                    <div className="PreviewAndTestModal__previewDivSubject">
                      {t('crusade.campaigns.emailSubject', {
                        subject: emailData.subject,
                      })}
                    </div>
                    <PendingContent
                      loading={loading}
                      isError={Boolean(error)}
                      loader={Spinner}
                    >
                      <Iframe
                        html={emailData.emailBodyPreview}
                        title={emailData.campaignId}
                      />
                      {/* <iframe srcDoc={emailData.emailTemplateContent?.body}/> */}
                    </PendingContent>
                  </div>
                )}
              </>
            ) : (
              <div className="PreviewAndTestModal__previewDiv">
                <div className="PreviewAndTestModal__header">
                  <div className="PreviewAndTestModal__dropdownContainer">
                    <p>{t('crusade.campaigns.device')}</p>
                    <Dropdown
                      label={
                        selectedDevice !== ''
                          ? deviceList?.items.find(
                              (item: any) => item.title === selectedDevice,
                            )?.title
                          : deviceList?.label
                      }
                    >
                      {deviceList?.items.map((item: any) => (
                        <DropdownSelectableItem
                          key={generateKey()}
                          selected={selectedDevice === item.title}
                          onClick={() => onDeviceSelect(item.title)}
                        >
                          {item.title}
                        </DropdownSelectableItem>
                      ))}
                    </Dropdown>
                  </div>
                  {selectedDevice === t('crusade.campaigns.iPhoneLabel') && (
                    <div className="PreviewAndTestModal__dropdownContainer">
                      <p>{t('crusade.campaigns.notificationState')}</p>
                      <Dropdown
                        label={
                          selectedScreen !== ''
                            ? screenList?.items.find(
                                (item: any) => item.title === selectedScreen,
                              )?.title
                            : screenList?.label
                        }
                      >
                        {screenList?.items.map((item: any) => (
                          <DropdownSelectableItem
                            key={generateKey()}
                            selected={selectedScreen === item.title}
                            onClick={() => onScreenSelect(item.title)}
                          >
                            {item.title}
                          </DropdownSelectableItem>
                        ))}
                      </Dropdown>
                    </div>
                  )}
                </div>
                {selectedDevice === t('crusade.campaigns.iPhoneLabel')
                  ? renderIPhoneScreen()
                  : renderAndroidScreen()}
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="PreviewAndTestModal__doneBtnWrapper">
          <Button styleVariant="superprimary" onClick={handleModalClose}>
            {t('done')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
