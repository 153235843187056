import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import moment from 'moment';
import {
  Dropdown,
  DropdownItem,
  IconButton,
  Icons,
  notify,
  Pagination,
  Status,
  Table,
} from 'plume-ui';
import {
  DataRow,
  Heading,
  SortDirection,
} from 'plume-ui/dist/components/Table/Table';

import { DependencyContainer } from 'DependencyContainer';
import { useSyncs } from 'features/syncs/hooks/useSyncs';
import { SyncStatusTypes } from 'features/syncs/types';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import { partnerIdAtom } from 'store/state/appState';
import { selectedSegmentAtom } from 'store/state/audienceFlowState';
import {
  syncsByChannelSelector,
  syncsSearchAtom,
} from 'store/state/syncsState';

const { syncsService } = new DependencyContainer();
const PER_PAGE = 10;

const SyncsTable: FunctionComponent = () => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const partnerId = useRecoilValue(partnerIdAtom);
  const syncSearch = useRecoilValue(syncsSearchAtom);
  const syncsByChannel = useRecoilValue(syncsByChannelSelector);
  const setSelectedSegment = useSetRecoilState(selectedSegmentAtom);
  const { runFetch } = useSyncs();

  const [page, setPage] = useState(0);

  useEffect(() => {
    setSelectedSegment(undefined);
  }, []);

  const renderDate = (syncDate: string) => {
    const today = new Date().toISOString();
    const yesterday = new Date(today);
    const formattedDate = moment.utc(syncDate).local().format('LL');

    if (!syncDate) {
      return 'N/A';
    }

    yesterday.setDate(yesterday.getDate() - 1);

    if (syncDate === today) {
      return t('generate.syncs.today');
    } else if (syncDate === yesterday.toISOString()) {
      return t('generate.syncs.yesterday');
    }

    return formattedDate;
  };

  const getConfirmationModalBody = (platform: string) => {
    return t('syncCard.deleteConfirmationMessage', { channel: platform });
  };

  const deleteSync = async (segmentId: string, syncId: string) => {
    try {
      const response = await syncsService.deleteSync(
        partnerId,
        segmentId,
        syncId,
      );
      if (response.data) {
        runFetch();
        notify({
          title: t('syncs.deleteNotification'),
          body: '',
          type: 'success',
        });
      }
    } catch (err) {
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
    }
  };

  const confirmDeleteSync = (
    segmentId: string,
    syncId: string,
    destinationPlatform: string,
  ) => {
    showModal(
      MODAL_TYPES.CONFIRMATION_MODAL,
      {
        title: t('syncs.deleteModal.title'),
        body: getConfirmationModalBody(destinationPlatform),
        onConfirm: () => {
          setTimeout(() => {
            deleteSync(segmentId, syncId);
          }, 50);
        },
        isOpen: true,
        multipleLines: true,
      },
      MODAL_TYPES.CONFIRMATION_MODAL,
    );
  };

  const handleSyncStateChange = async (
    segmentId: string,
    syncId: string,
    syncState: 'active' | 'paused',
  ) => {
    const payload = {
      schedule: '',
      fieldsToSync: '',
      state: syncState,
    };

    try {
      await syncsService.updateSync(partnerId, segmentId, syncId, payload);
      runFetch();
      notify({
        type: 'success',
        title: t('success'),
        body:
          syncState === 'paused'
            ? t('generate.syncs.syncPaused')
            : t('generate.syncs.syncEnabled'),
      });
    } catch (err) {
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
    }
  };

  const syncData = useMemo(() => {
    return Object.keys(syncsByChannel)
      .map((channel) => {
        return syncsByChannel[channel];
      })
      .flat();
  }, [syncsByChannel]);

  const tableData = useMemo(
    () =>
      syncData.map((sync) => ({
        segmentName: sync.segmentName,
        segmentId: sync.segmentId,
        syncId: sync.syncId,
        destinationPlatform: sync.channel.channelName || 'N/A',
        lastSync: sync.latestSyncExecutedAt,
        status: sync.status,
        state: sync.state,
        frequency: t(
          `settings.channel.syncFrequency.${sync.channel?.channelProperties?.scheduler?.frequency?.toLowerCase()}`,
          { defaultValue: t('generate.syncs.frequency.daily') },
        ),
        segmentSize: String(sync.segmentCount) || '0',
      })),
    [syncData],
  );

  const filteredTableData = useMemo(
    () =>
      tableData.filter(
        (sync) =>
          sync.segmentName?.toLowerCase().includes(syncSearch.toLowerCase()) ||
          sync.destinationPlatform
            ?.toLowerCase()
            .includes(syncSearch.toLowerCase()) ||
          sync.status?.toLowerCase().includes(syncSearch.toLowerCase()) ||
          sync.frequency?.toLowerCase().includes(syncSearch.toLowerCase()),
      ),
    [syncSearch, tableData],
  );

  const paginatedData = useMemo(
    () => filteredTableData.slice(page * PER_PAGE, page * PER_PAGE + PER_PAGE),
    [filteredTableData, page],
  );

  const headerRows: Heading[] = [
    {
      name: t('generate.syncs.tableHeaders.segmentName'),
      fieldName: 'segmentName',
    },
    {
      name: t('generate.syncs.tableHeaders.destinationPlatform'),
      fieldName: 'destinationPlatform',
    },
    {
      name: t('generate.syncs.tableHeaders.lastSync'),
      fieldName: 'lastSync',
      render: (row: DataRow) => renderDate(row.lastSync),
      sortFnGen: (direction: SortDirection) => (a: DataRow, b: DataRow) => {
        if (!a.lastSync) return 1;
        if (!b.lastSync) return -1;
        return (moment(a.lastSync).isBefore(b.lastSync) ? -1 : 1) * direction;
      },
    },
    {
      name: t('generate.syncs.tableHeaders.status'),
      fieldName: 'status',
      sortFnGen: (direction: SortDirection) => (a: DataRow, b: DataRow) => {
        if (!a.status) return 1;
        if (!b.status) return -1;
        return a.status.localeCompare(b.status) * direction;
      },
      render: (row: DataRow) => {
        const label =
          row.state === 'paused'
            ? t(`generate.syncs.state.paused`)
            : t(`generate.syncs.status.${row.status}`, {
                defaultValue: '',
              });
        if (!label) return 'N/A';
        return (
          <Status
            label={label}
            color={
              row.state === 'paused'
                ? 'warning'
                : {
                    failure: 'error',
                    success: 'ok',
                  }[row.status as SyncStatusTypes]
            }
          />
        );
      },
    },
    {
      name: t('generate.syncs.tableHeaders.frequency'),
      fieldName: 'frequency',
    },
    {
      name: t('generate.syncs.tableHeaders.segmentSize'),
      fieldName: 'segmentSize',
    },
    {
      name: '',
      sortable: false,
      render: (sync: DataRow) => (
        <Dropdown
          listPosition="right"
          closeOnItemClick
          openInPortal
          button={
            <IconButton>
              <Icons.DotsVerticalIcon />
            </IconButton>
          }
        >
          <DropdownItem
            onClick={() =>
              handleSyncStateChange(
                sync.segmentId,
                sync.syncId,
                sync.state === 'paused' ? 'active' : 'paused',
              )
            }
          >
            {sync.state === 'paused'
              ? t('generate.syncs.enableSync')
              : t('generate.syncs.pauseSync')}
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              confirmDeleteSync(
                sync.segmentId,
                sync.syncId,
                sync.destinationPlatform,
              )
            }
          >
            {t('generate.syncs.deleteSync')}
          </DropdownItem>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="GenerateSyncs__cardContainer">
      <Table headerRow={headerRows} dataRows={paginatedData} />
      <div className="SyncsTable__pagination">
        <Pagination
          expandDirection="auto"
          onPageSelect={setPage}
          currentPage={page}
          totalPageCount={Math.ceil(
            (syncSearch ? filteredTableData.length : tableData.length) /
              PER_PAGE,
          )}
        />
      </div>
    </div>
  );
};

export default SyncsTable;
