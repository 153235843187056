import { createContext, Dispatch, SetStateAction } from 'react';
import {
  AddChannelModalSubTabs,
  AddChannelModalTabs,
  AvailableIntegration,
  AvailableIntegrationTypes,
  AvailableModalActionViews,
  Channel,
} from 'features/configuration/types';
import { Maybe } from '../../../../types';
import { AdConnectedAccountResponse } from 'features/configuration/integrationsState';
import { CreateChannelRequest } from 'features/integrations/types';

export type ConfigChannelModalContextValues = {
  activeTabName: Maybe<AddChannelModalTabs>;
  setActiveTabName: Dispatch<SetStateAction<AddChannelModalTabs>>;
  activeSubTabName: Maybe<AddChannelModalSubTabs>;
  selectedAccountType: Maybe<AvailableIntegrationTypes>;
  handleIntegrationChange: (integration: AvailableIntegration) => void;
  handleSteps: (flag: AvailableModalActionViews) => void;
  handleFinish: () => void;
  onSelectAccount: (adAccountId: string) => void;
  getSelectedAccountId: () => Maybe<string>;
  adConnectedAccount: Maybe<AdConnectedAccountResponse>;
  isRequestInProgress: boolean;
  setIsRequestInProgress: Dispatch<SetStateAction<boolean>>;
  newChannel: Maybe<Partial<CreateChannelRequest>>;
  newChannelResponse: Maybe<Channel>;
  setNewChannel: Dispatch<SetStateAction<Maybe<Partial<CreateChannelRequest>>>>;
  nextStepDisabled: boolean;
  setNextStepDisabled: Dispatch<SetStateAction<boolean>>;
};

export const ConfigChannelModalContext = createContext<
  ConfigChannelModalContextValues
>({
  activeTabName: undefined,
  setActiveTabName: () => {},
  activeSubTabName: undefined,
  selectedAccountType: undefined,
  handleIntegrationChange: () => {},
  handleSteps: () => {},
  handleFinish: () => {},
  onSelectAccount: (adAccountId: string) => {},
  getSelectedAccountId: () => undefined,
  adConnectedAccount: undefined,
  setIsRequestInProgress: () => {},
  isRequestInProgress: false,
  newChannel: undefined,
  setNewChannel: () => {},
  nextStepDisabled: false,
  setNextStepDisabled: () => {},
  newChannelResponse: undefined,
});
