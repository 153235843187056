import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import {
  Box,
  Button,
  Icons,
  InputField,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  notify,
  PendingContent,
  Slider,
  Spinner,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import FormattedMessage from 'utils/components/FormattedMessage';
import millify from 'millify';
import { DependencyContainer } from 'DependencyContainer';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { useAudience } from 'features/generate/audience/hooks/useAudience';
import {
  activeNodeAtom,
  selectedSegmentAtom,
} from 'store/state/audienceFlowState';
import { SegmentSplitNodeResponse } from 'features/generate/audience/types';
import { getSegmentTreeByIdSelector } from 'store/state/audienceFlowState';
import { useTrackEvent } from 'features/trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from 'mixPanelEvents';
import { AxiosError } from 'axios';

type SplitSegmentsModalProps = {
  id: string;
  segmentName: string;
  totalUser: number;
  mode: 'new' | 'edit';
  parentId?: string;
  closeEvent: () => void;
};

type userGroup = {
  group: number;
  groupName: string;
  userCountPercentage: number;
  userRowCount?: number;
};

export const SplitSegmentsModal: FunctionComponent<SplitSegmentsModalProps> = ({
  id,
  totalUser,
  segmentName,
  mode,
  parentId,
  closeEvent,
}) => {
  const { t } = useTranslation();
  const { showModal, hideModal } = useGlobalModalContext();
  const [sliderValue, setSliderValue] = useState(0);
  const [users, setUsers] = useState<userGroup[]>([
    { group: 1, userCountPercentage: 50, groupName: '' },
    { group: 2, userCountPercentage: 50, groupName: '' },
  ]);
  const { audienceService } = new DependencyContainer();
  const [isDisabledSave, setIsDisabledSave] = useState(true);
  const [totalSegmentUsers, setTotalSegmentUsers] = useState(0);

  const { runFetch } = useAudience();

  const partnerId = useRecoilValue(partnerIdAtom);
  const setActiveNode = useSetRecoilState(activeNodeAtom);
  const setSelectedSegment = useSetRecoilState(selectedSegmentAtom);

  const parentSegment = useRecoilValue(getSegmentTreeByIdSelector(parentId!));

  const trackEvent = useTrackEvent();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsDisabledSave(true);

    // ------ Deactivating node ------- //
    return () => {
      setActiveNode('');
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (mode === 'edit') {
        setLoading(true);
        const splitSegmentUsers: SegmentSplitNodeResponse = await audienceService.getSplitNode(
          partnerId!,
          parentId!,
        );
        let totalUsers: number = 0;
        const userGroups = splitSegmentUsers.data.map((user, idx) => {
          totalUsers = totalUsers + user.rowCount;
          return {
            group: idx + 1,
            groupName: user.segmentName,
            userCountPercentage: parseInt(user.percentage),
            userRowCount: user.rowCount,
          };
        });
        setUsers(userGroups);

        setTotalSegmentUsers(totalUsers);
        setLoading(false);
      } else {
        setTotalSegmentUsers(totalUser);
      }
    })();
  }, [mode]);

  useEffect(() => {
    validateGroupName();
    validateNoZero();
    validateSplitSum();
  }, [users]);

  const scrollContainer = {
    marginRight: '2px',
    maxHeight: '628px',
    padding: '32px',
  };

  const addGroup = () => {
    const updatedUser = users.map((user) => {
      return {
        ...user,
        userCountPercentage: user.userCountPercentage || 0,
        groupName: user.groupName || '',
      };
    });
    setUsers([
      ...updatedUser,
      { group: users.length + 1, userCountPercentage: 0, groupName: '' },
    ]);
    setIsDisabledSave(true);
  };

  const handleBalanceSplits = () => {
    const updatedUser = users.map((user, idx) => {
      const split = Math.trunc(100 / users.length);
      const remainder = 100 - split * (users.length - 1);
      if (idx < users.length - 1) {
        return { ...user, userCountPercentage: split };
      } else {
        return { ...user, userCountPercentage: remainder };
      }
    });
    setUsers(updatedUser);
  };

  const handleChanges = (groupId: number, value: number) => {
    const updatedUser = users.map((user) => {
      if (user.group === groupId) {
        return { ...user, userCountPercentage: sliderValue < 99 ? value : 1 };
      } else {
        return { ...user };
      }
    });
    setUsers(updatedUser);
  };

  const handleCloseModal = () => {
    runFetch();
    closeEvent();
    hideModal(MODAL_TYPES.SPLIT_SEGMENTS_MODAL);
    setSelectedSegment(undefined);
  };

  const handleSave = async () => {
    try {
      setIsDisabledSave(true);
      const splitNodeDto = {
        childSplit: users.map((user: any) => {
          return {
            segmentName: user.groupName,
            splitPercentage: user.userCountPercentage,
          };
        }),
      };

      await audienceService.addSplitNode(partnerId, id, splitNodeDto);

      notify({
        title: t('success'),
        body: t('splitSegmentsModal.splitNodeAdded'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.SEGMENT_CREATE_SUCCESS,
        additionalContent: {
          SEGMENT_ID: id,
          SEGMENT_TYPE: 'split',
        },
      });
      setIsDisabledSave(false);
      handleCloseModal();
    } catch (error) {
      setIsDisabledSave(false);
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
      const mappedError = error as AxiosError;
      trackEvent({
        eventName: MixPanelEvents.SEGMENT_CREATE_FAILURE,
        additionalContent: {
          SEGMENT_ID: id,
          SEGMENT_TYPE: 'split',
          ERROR_MESSAGE: mappedError.response?.data.error,
        },
      });
    }
  };

  const handleUpdate = async () => {
    try {
      setIsDisabledSave(true);
      const splitNodeDto = {
        childSplit: users.map((user: any) => {
          return {
            segmentName: user.groupName,
            splitPercentage: user.userCountPercentage,
          };
        }),
      };

      await audienceService.updateSplitNode(partnerId, parentId!, splitNodeDto);

      notify({
        title: t('success'),
        body: t('splitSegmentsModal.splitNodeUpdated'),
        type: 'success',
      });

      trackEvent({
        eventName: MixPanelEvents.SEGMENT_EDIT_SUCCESS,
        additionalContent: {
          SEGMENT_ID: parentId,
          SEGMENT_TYPE: 'split',
        },
      });
      setIsDisabledSave(false);
      handleCloseModal();
    } catch (error) {
      setIsDisabledSave(false);
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
      const mappedError = error as AxiosError;
      trackEvent({
        eventName: MixPanelEvents.SEGMENT_CREATE_FAILURE,
        additionalContent: {
          SEGMENT_ID: parentId,
          SEGMENT_TYPE: 'split',
          ERROR_MESSAGE: mappedError.response?.data.error,
        },
      });
    }
  };

  const handleDeleteSpitSegment = async () => {
    try {
      if (partnerId === undefined) {
        return;
      }
      await audienceService.deleteSplitNode(partnerId, parentId!);

      notify({
        title: t('success'),
        body: t('splitSegmentsModal.splitNodeDeleted'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.SEGMENT_DELETE_SUCCESS,
        additionalContent: {
          SEGMENT_ID: id,
          SEGMENT_TYPE: 'split',
        },
      });
      handleCloseModal();
    } catch (error) {
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
      const mappedError = error as AxiosError;
      trackEvent({
        eventName: MixPanelEvents.SEGMENT_DELETE_FAILURE,
        additionalContent: {
          SEGMENT_ID: id,
          SEGMENT_TYPE: 'split',
          ERROR_MESSAGE: mappedError.response?.data.error,
        },
      });
    }
  };

  const validateSplitSum = () => {
    const sum = users.reduce(
      (sum: number, user: userGroup) => sum + user.userCountPercentage,
      0,
    );

    if (sum !== 100) {
      setIsDisabledSave(true);
    }
  };

  const validateGroupName = () => {
    let isValidate = true;
    users.forEach((user) => {
      if (!user.groupName) {
        isValidate = false;
      }
    });
    setIsDisabledSave(!isValidate);
  };

  const validateNoZero = () => {
    const zeroPercentUser = users.find(
      (user) => user.userCountPercentage === 0,
    );
    if (!!zeroPercentUser?.group) {
      setIsDisabledSave(true);
    }
  };

  const renderUserCountLabel = (user: userGroup) => {
    return mode === 'edit'
      ? user.userRowCount
      : totalSegmentUsers &&
          millify((user.userCountPercentage * totalSegmentUsers) / 100, {
            precision:
              (user.userCountPercentage * totalSegmentUsers) / 100 > 1000
                ? 1
                : 0,
            lowercase: true,
          });
  };

  return (
    <>
      <Modal
        classes={(current: ModalStyles) => ({
          ...current,
          root: `${current.root} SplitSegmentsModal`,
        })}
        isOpen
        onRequestClose={() => handleCloseModal()}
      >
        <ModalHeader
          title={
            mode === 'new' ? (
              <FormattedMessage id="splitSegmentsModal.title" />
            ) : (
              <FormattedMessage id="splitSegmentsModal.editTitle" />
            )
          }
        />
        <ModalBody
          classes={(current) => ({
            ...current,
            root: `${current.root} SplitSegmentsModal__body`,
          })}
        >
          <div className="SplitSegmentsModal__header">
            <p className="SplitSegmentsModal__segmentName">
              {mode === 'edit' ? parentSegment!.segmentName : segmentName}
            </p>
            {mode !== 'edit' && (
              <p className="SplitSegmentsModal__segmentInfo">
                <FormattedMessage id="splitSegmentsModal.segmentInfoLabel" />
              </p>
            )}
            {mode === 'edit' && (
              <Button
                classes={(current) => ({
                  ...current,
                  root: `${current.root} SegmentsModal__deleteBtn`,
                })}
                styleVariant="navigation"
                icon={<Icons.TrashIcon />}
                onClick={() =>
                  showModal(
                    MODAL_TYPES.CONFIRMATION_MODAL,
                    {
                      title: t('segmentsModal.confirmDeleteSplitTitle'),
                      body: t('segmentsModal.confirmDeleteSplitBody'),
                      onConfirm: () => handleDeleteSpitSegment(),
                      isOpen: true,
                      multipleLines: true,
                    },
                    MODAL_TYPES.CONFIRMATION_MODAL,
                  )
                }
              >
                <FormattedMessage id="segmentsModal.deleteSplit" />
              </Button>
            )}
          </div>
          <div className="SplitSegmentsModal__main">
            {users.map((user, index) => {
              return (
                <div className="SplitSegmentsModal__wrapper" key={index}>
                  <div className="SplitSegmentsModal__label">
                    {t('splitSegmentsModal.groupLabel', { name: user.group })}
                  </div>
                  <div className="SplitSegmentsModal__inputWrapper">
                    <Box>
                      <Box>
                        <PendingContent loading={loading} loader={Spinner}>
                          <div className="SplitSegmentsModal__userContainer">
                            <p className="SplitSegmentsModal__percentageLabel">
                              {`${user.userCountPercentage}%`}
                            </p>
                            <Slider
                              min={1}
                              max={99}
                              value={user.userCountPercentage}
                              onChange={(e: any) =>
                                handleChanges(
                                  user.group,
                                  parseInt(e.currentTarget.value),
                                )
                              }
                              disabled={mode === 'edit'}
                            />
                            <p className="SplitSegmentsModal__userLabel">
                              {renderUserCountLabel(user)}
                            </p>
                          </div>
                        </PendingContent>
                      </Box>
                      <div className="SplitSegmentsModal__inputContaine">
                        <InputField
                          label={t('splitSegmentsModal.nameLabel')}
                          value={user.groupName}
                          onInput={(e) => {
                            user.groupName = (e.target as HTMLInputElement).value;
                            validateGroupName();
                            validateNoZero();
                            validateSplitSum();
                          }}
                        />
                      </div>
                    </Box>
                  </div>
                </div>
              );
            })}
          </div>
          {mode === 'new' && (
            <div className="SplitSegmentsModal__buttonContainer">
              <Button
                styleVariant="action"
                onClick={() => handleBalanceSplits()}
              >
                <FormattedMessage id="splitSegmentsModal.balanceSplitsButton" />
              </Button>
              <Button
                styleVariant="action"
                icon={<Icons.AddIcon />}
                onClick={() => addGroup()}
              >
                <FormattedMessage id="splitSegmentsModal.addGroupButton" />
              </Button>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            styleVariant="tertiary-grey"
            onClick={() => handleCloseModal()}
          >
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            styleVariant="superprimary"
            onClick={() => {
              return mode === 'new' ? handleSave() : handleUpdate();
            }}
            disabled={isDisabledSave}
          >
            <FormattedMessage id="save" />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
