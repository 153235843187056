import {
  Button,
  IconButton,
  Icons,
  InputField,
  StaticCard,
  Dropdown,
  Divider,
  DropdownItem,
  Logo,
} from 'plume-ui';
import {
  InputMessage,
  InputMessageStatus,
} from 'plume-ui/dist/components/InputField/InputField';
import React, {
  FormEvent,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  editingCampaignAtom,
  formUpdated,
} from '../../../store/state/campaignState';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import {
  CampaignDtoType,
  CampaignFormikFields,
  CampaignGenericFormField,
  CampaignPushNotificationCardValues,
  CampaignStatus,
  PushCampaignMessageOnClickEnum,
} from '../types';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { brandingCompanyNameAtom } from 'store/state/configurationState';
import { useFormikContext } from 'formik';
import { CURRENT_TIME, DATE_TODAY, generateKey } from 'utils/helpers';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { isValidRedirectUrl } from '../util/crusadeHelpers';

const CampaignPushNotificationCard: FunctionComponent<CampaignGenericFormField<
  CampaignPushNotificationCardValues
>> = ({ form, field }) => {
  const { values } = useFormikContext<CampaignDtoType>();
  const { t } = useTranslation();
  const [inEditMode, setInEditMode] = useState(false);

  const { showModal } = useGlobalModalContext();
  const editingCampaign = useRecoilValue(editingCampaignAtom);
  const brandingCompanyName = useRecoilValue(brandingCompanyNameAtom);
  const setFormFieldsUpdated = useSetRecoilState(formUpdated);

  const onClickBehaviorDropdownOptions = [
    t('crusade.campaigns.messageCard.openApp'),
    t('crusade.campaigns.messageCard.redirectToWebURL'),
  ];

  const scrollContainer = {
    marginRight: '2px',
    height: '280px',
    padding: '32px',
  };

  useEffect(() => {
    setInEditMode(!editingCampaign);
  }, [editingCampaign]);

  const urlErrorMsg = useMemo(() => {
    const url = field.value.onClickBehavior?.redirectURL;
    let valid: boolean;
    const errorMessage = {
      status: 'error' as InputMessageStatus,
      message: t('invalidUrlError'),
    };

    if (!url) {
      return errorMessage;
    } else {
      valid = isValidRedirectUrl(url);
    }

    return valid ? ({} as InputMessage) : errorMessage;
  }, [field.value.onClickBehavior?.redirectURL]);

  const handleNotificationPreview = () => {
    showModal(
      MODAL_TYPES.PREVIEW_AND_TEST_MODAL,
      { mode: 'notificationPreview', pushCampaign: field.value },
      MODAL_TYPES.PREVIEW_AND_TEST_MODAL,
    );
  };

  const setRedirectURL = (redirectURL: string) => {
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_PUSH_NOTIFICATION_CARD, {
      ...field.value,
      onClickBehavior: {
        action: PushCampaignMessageOnClickEnum.REDIRECT_TO_WEB_URL,
        redirectURL,
      },
    });
  };

  const handleDropdownClick = (dropdownOption: string) => {
    const isSelectedRedirectToWebURL =
      dropdownOption === t('crusade.campaigns.messageCard.redirectToWebURL');

    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_PUSH_NOTIFICATION_CARD, {
      ...field.value,
      onClickBehavior: {
        ...field.value?.onClickBehavior,
        action: isSelectedRedirectToWebURL
          ? PushCampaignMessageOnClickEnum.REDIRECT_TO_WEB_URL
          : PushCampaignMessageOnClickEnum.OPEN_APP,
      },
    });
  };

  const cardActions = () => {
    if (!editingCampaign) {
      return undefined;
    } else if (!inEditMode) {
      return [
        <IconButton
          classes={(current) => ({
            ...current,
            root: `${current.root} EditCard__editIcon`,
          })}
          onClick={() => setInEditMode(true)}
        >
          <Icons.EditIcon />
        </IconButton>,
      ];
    }
  };

  const renderMessageTitle = () => {
    if (field.value.title || field.value.title === '') {
      return field.value.title;
    } else if (
      brandingCompanyName &&
      values?.campaignInformationCard?.status !== CampaignStatus.Active
    ) {
      setFormFieldsUpdated(true);
      form.setFieldValue(CampaignFormikFields.CAMPAIGN_PUSH_NOTIFICATION_CARD, {
        ...field.value,
        title: brandingCompanyName,
      });

      return brandingCompanyName;
    } else {
      return '';
    }
  };

  return (
    <>
      <div className="EditCard">
        <StaticCard
          title={t('crusade.campaigns.messages')}
          actions={cardActions()}
          classes={(current) => ({
            ...current,
            title: `${current.title} EditCard__cardHeader-title`,
          })}
        >
          <>
            <div className="EditCard__wrapper title">
              <p className="EditCard__label">
                <FormattedMessage id="crusade.campaigns.messageCard.title" />:
              </p>
              {inEditMode ? (
                <div className="EditCard__inputWrapper">
                  <InputField
                    classes={(current) => ({
                      ...current,
                      root: `${current.root} EditCard__campaignInfoCardInput`,
                    })}
                    onInput={(e) => {
                      setFormFieldsUpdated(true);
                      form.setFieldValue(
                        CampaignFormikFields.CAMPAIGN_PUSH_NOTIFICATION_CARD,
                        {
                          ...field.value,
                          title:
                            (e.target as HTMLInputElement).value === undefined
                              ? ''
                              : (e.target as HTMLInputElement).value,
                        },
                      );
                    }}
                    value={renderMessageTitle()}
                    messages={
                      !field.value?.title?.length
                        ? [
                            {
                              status: 'hint' as InputMessageStatus,
                              message: t(
                                'crusade.campaigns.messageCard.titlePrompt',
                              ),
                            },
                          ]
                        : [{}]
                    }
                  />
                </div>
              ) : (
                <p className="EditCard__campaignNonEditCardValue">
                  {renderMessageTitle()}
                </p>
              )}
            </div>
            <div className="EditCard__wrapper">
              <p className="EditCard__label">
                <FormattedMessage id="crusade.campaigns.messageCard.message" />:
              </p>
              {inEditMode ? (
                <div className="EditCard__campaignInfoCardInput">
                  <InputField
                    classes={(current) => ({
                      ...current,
                      root: current.root,
                      messages: `${current.messages} EditCard__campaignMessageBodyInput`,
                    })}
                    onInput={(e) => {
                      setFormFieldsUpdated(true);
                      form.setFieldValue(
                        CampaignFormikFields.CAMPAIGN_PUSH_NOTIFICATION_CARD,
                        {
                          ...field.value,
                          messageBody:
                            (e.target as HTMLInputElement).value || '',
                        },
                      );
                    }}
                    value={field.value?.messageBody}
                    maxLength={160}
                    messages={[
                      {
                        status: 'hint',
                        message: !field.value?.messageBody
                          ? t('fieldRequired')
                          : '',
                      },
                      {
                        status: 'hint' as InputMessageStatus,
                        message: t(
                          'crusade.campaigns.messageCard.charactersRemainingLabel',
                          {
                            remaining:
                              field.value?.messageBody === undefined
                                ? 160
                                : 160 - field.value?.messageBody.length,
                          },
                        ),
                      },
                    ]}
                  />
                </div>
              ) : (
                <p className="EditCard__campaignNonEditCardValue">
                  {field.value?.messageBody}
                </p>
              )}
            </div>
            <div className="EditCard__wrapper-onClickBehavior">
              <p className="EditCard__label">
                <FormattedMessage id="crusade.campaigns.messageCard.onClickBehavior" />
                :
              </p>
              <div className="EditCard__valueContainer">
                <div className="EditCard__dropdownContainer-onClickDropdown">
                  {inEditMode ? (
                    <>
                      <Dropdown
                        classes={(current) => ({
                          ...current,
                          root: `${current.root} EditCard__dropdownContainer-onClickDropdown-dropdown`,
                        })}
                        label={
                          field.value?.onClickBehavior?.action ===
                          PushCampaignMessageOnClickEnum.REDIRECT_TO_WEB_URL
                            ? t(
                                'crusade.campaigns.messageCard.redirectToWebURL',
                              )
                            : t('crusade.campaigns.messageCard.openApp')
                        }
                        openInPortal={true}
                        closeOnItemClick={true}
                      >
                        {onClickBehaviorDropdownOptions.map((option) => (
                          <DropdownItem
                            key={generateKey()}
                            onClick={() => handleDropdownClick(option)}
                          >
                            {option}
                          </DropdownItem>
                        ))}
                      </Dropdown>
                      {field.value?.onClickBehavior?.action ===
                        PushCampaignMessageOnClickEnum.REDIRECT_TO_WEB_URL && (
                        <div className="EditCard__campaignInfoCardInput-redirectURL">
                          <InputField
                            onInput={(e: FormEvent<HTMLInputElement>) => {
                              const url = e.currentTarget.value || '';
                              setRedirectURL(url);
                            }}
                            placeholder={t(
                              'crusade.campaigns.messageCard.urlPlaceholder',
                            )}
                            value={
                              field.value?.onClickBehavior?.redirectURL
                                ? field.value?.onClickBehavior?.redirectURL
                                : ''
                            }
                            messages={[urlErrorMsg]}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <p className="EditCard__campaignNonEditCardValue">
                      {field.value?.onClickBehavior?.action ===
                      PushCampaignMessageOnClickEnum.REDIRECT_TO_WEB_URL
                        ? t('crusade.campaigns.messageCard.redirectTo', {
                            url: field.value?.onClickBehavior?.redirectURL,
                          })
                        : t('crusade.campaigns.messageCard.openApp')}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <Button
              classes={(current) => ({
                ...current,
                root: `${current.root} EditCard__emailPreviewAndTestBtn`,
              })}
              styleVariant="quaternary"
              onClick={handleNotificationPreview}
            >
              <FormattedMessage id="crusade.campaigns.emailPreviewAndTest" />
            </Button>
            <PerfectScrollbar
              style={scrollContainer}
              options={{ maxScrollbarLength: 100 }}
            >
              <div className="PreviewAndTestModal__deviceWrapper">
                <div className="PreviewAndTestModal__device">
                  <div className="PreviewAndTestModal__iconContainer">
                    <Icons.WifiIcon className="PreviewAndTestModal__icon" />
                    <Icons.GraphBarIcon className="PreviewAndTestModal__icon" />
                  </div>
                  <div className="PreviewAndTestModal__personeIconContainer">
                    <Icons.PersonStrokeIcon className="PreviewAndTestModal__personeIcon" />
                  </div>
                  <p className="PreviewAndTestModal__screenTime">
                    {CURRENT_TIME}
                  </p>
                  <p className="PreviewAndTestModal__screenDate">
                    {DATE_TODAY}
                  </p>
                  <div className="PreviewAndTestModal__notificationContainer">
                    <div className="PreviewAndTestModal__logoContainer">
                      <Logo />
                    </div>
                    <div>
                      <p className="PreviewAndTestModal__appName">
                        {field.value?.title ||
                          brandingCompanyName ||
                          t('crusade.campaigns.title')}
                      </p>
                      <p className="PreviewAndTestModal__notificationTitle">
                        {field.value?.messageBody ||
                          t('crusade.campaigns.message')}
                      </p>
                    </div>
                    <p className="PreviewAndTestModal__time">
                      <FormattedMessage id="crusade.campaigns.nowLabel" />
                    </p>
                  </div>
                </div>
                <div className="PreviewAndTestModal__navigation-btn"></div>
              </div>
            </PerfectScrollbar>
          </>
        </StaticCard>
      </div>
    </>
  );
};

export default CampaignPushNotificationCard;
