import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { InputField, Logo, Toggler } from 'plume-ui';
import * as Yup from 'yup';
import { AvailableIntegrationTypes } from '../../types';
import { ConfigChannelModalContext } from './AddChannelModalContext';
import {
  mapSelectedIntegrationToConfigureSubtitle,
  mapSelectedIntegrationToConfigureTitle,
  mapSelectedIntegrationToIcon,
} from './AddChannelConfigure';
import _, { update } from 'lodash';
import { availableConnectionMethodsForChannel } from 'features/configuration/config';
import { useTranslation } from 'react-i18next';
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikProps,
  FormikValues,
} from 'formik';
import { ChannelSyncFrequency } from 'features/integrations/types';
import { useRecoilValue } from 'recoil';
import { channelsAtom } from 'store/state/channelState';

type AvailableMethod = typeof availableConnectionMethodsForChannel[keyof typeof availableConnectionMethodsForChannel][number];

const CustomError: FunctionComponent<{ name: string }> = ({ name }) => {
  return (
    <ErrorMessage name={name}>
      {(msg: string) => <div className="AddChannelModal__errorMsg">{msg}</div>}
    </ErrorMessage>
  );
};

const AddChannelIntroduction: FunctionComponent = () => {
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<{}>>(null);
  const context = useContext(ConfigChannelModalContext);
  const availableMethods =
    availableConnectionMethodsForChannel[
      context.selectedAccountType || AvailableIntegrationTypes.Google
    ];

  const [connectionType, setConnectionType] = React.useState<AvailableMethod>(
    availableMethods[0],
  );

  const validationSchema = useMemo(() => {
    if (connectionType === 'apiKey')
      return Yup.object().shape({
        channelName: Yup.string().required('Required'),
        channelProperties: Yup.object().shape({
          baseUrl: Yup.string().url().required('Required'),
          apiKey: Yup.string().required('Required'),
          apiKeyLabel: Yup.string(),
          scheduler: Yup.object().shape({
            frequency: Yup.string().required('Required'),
          }),
        }),
      });
    return Yup.object().shape({
      channelName: Yup.string().required('Required'),
      channelProperties: Yup.object().shape({
        baseUrl: Yup.string().url().required('Required'),
        token: Yup.string().required('Required'),
        scheduler: Yup.object().shape({
          frequency: Yup.string().required('Required'),
        }),
      }),
    });
  }, [connectionType]);

  useEffect(() => {
    context.setNextStepDisabled(
      !validationSchema.isValidSync(context.newChannel || {}),
    );
  }, [context.newChannel, validationSchema]);

  useEffect(() => {
    const updatedChannel = _.cloneDeep(context.newChannel || {});
    if (connectionType === 'apiKey')
      delete updatedChannel.channelProperties?.token;
    else {
      delete updatedChannel.channelProperties?.apiKey;
      delete updatedChannel.channelProperties?.apiKeyLabel;
    }
    context.setNewChannel(updatedChannel);
  }, [connectionType]);

  const Icon =
    mapSelectedIntegrationToIcon[
      context.selectedAccountType as AvailableIntegrationTypes
    ] || React.Fragment;

  const handleUpdateChannelValue = (value: string, key: string[]) => {
    formRef?.current?.setFieldTouched(key.join('.'), true);
    formRef?.current?.setFieldValue(key.join('.'), value);
    const updatedChannel = _.cloneDeep(context.newChannel || {});
    _.set(updatedChannel, key, value);
    context.setNewChannel(updatedChannel);
    // weird formik bug where it doesn't validate properly
    setTimeout(() => {
      formRef?.current?.validateForm();
    }, 0);
  };

  if (
    context.selectedAccountType &&
    [
      AvailableIntegrationTypes.Braze,
      AvailableIntegrationTypes.Hubspot,
      AvailableIntegrationTypes.MailChimp,
    ].includes(context.selectedAccountType)
  )
    return (
      <Formik
        innerRef={formRef}
        initialValues={{
          channelName: context.newChannel?.channelName || '',
          channelDescription: context.newChannel?.channelDescription || '',
          channelProperties: {
            baseUrl: context.newChannel?.channelProperties?.baseUrl || '',
            apiKey: context.newChannel?.channelProperties?.apiKey || '',
            apiKeyLabel:
              context.newChannel?.channelProperties?.apiKeyLabel || '',
            token: context.newChannel?.channelProperties?.token || '',
            scheduler: {
              frequency:
                context.newChannel?.channelProperties?.scheduler?.frequency ||
                '',
            },
          },
        }}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        <Form>
          <div className="AddChannelModal__thirdPartyConnection">
            <p className="AddChannelModal__adsconfigureTitle">
              <FormattedMessage
                id={
                  mapSelectedIntegrationToConfigureTitle[
                    context.selectedAccountType as AvailableIntegrationTypes
                  ]
                }
              />
            </p>
            <p className="AddChannelModal__adsconfigureSubtitle">
              <FormattedMessage
                id={
                  mapSelectedIntegrationToConfigureSubtitle[
                    context.selectedAccountType as AvailableIntegrationTypes
                  ]
                }
              />
            </p>
            <div className="AddChannelModal__formInputs">
              <div className="AddChannelModal__formField">
                <InputField
                  label={t('settings.channel.createForm.channelName')}
                  value={context.newChannel?.channelName}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleUpdateChannelValue(e.target.value, ['channelName'])
                  }
                  name="channelName"
                  required
                />
                <CustomError name="channelName" />
              </div>
              {availableMethods.length > 1 && (
                <div className="AddChannelModal__formField">
                  <Toggler
                    value={connectionType}
                    onToggle={(selection) => {
                      setConnectionType(selection.key as typeof connectionType);
                    }}
                    toggleElements={[
                      {
                        label: t('settings.channel.createForm.apiKey'),
                        key: 'apiKey',
                      },
                      {
                        label: t('settings.channel.createForm.token'),
                        key: 'token',
                      },
                    ]}
                  />
                </div>
              )}
              <div className="AddChannelModal__formField">
                <InputField
                  value={context.newChannel?.channelProperties?.baseUrl}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleUpdateChannelValue(e.target.value, [
                      'channelProperties',
                      'baseUrl',
                    ])
                  }
                  name="channelProperties.baseUrl"
                  label={t('settings.channel.createForm.apiBaseUrl')}
                  required
                />

                <CustomError name="channelProperties.baseUrl" />
              </div>
              {connectionType === 'token' && (
                <div className="AddChannelModal__formField">
                  <InputField
                    value={context.newChannel?.channelProperties?.token}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleUpdateChannelValue(e.target.value, [
                        'channelProperties',
                        'token',
                      ])
                    }
                    label={t('settings.channel.createForm.token')}
                    name="token"
                    required
                  />
                  <CustomError name="channelProperties.token" />
                </div>
              )}
              {connectionType === 'apiKey' && (
                <>
                  <div className="AddChannelModal__formField">
                    <InputField
                      value={context.newChannel?.channelProperties?.apiKeyLabel}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleUpdateChannelValue(e.target.value, [
                          'channelProperties',
                          'apiKeyLabel',
                        ])
                      }
                      label={`${t(
                        'settings.channel.createForm.apiKeyLabel',
                      )} (${t('optional')})`}
                      name="apiKeyLabel"
                    />
                    <CustomError name="channelProperties.apiKeyLabel" />
                  </div>
                  <div className="AddChannelModal__formField">
                    <InputField
                      value={context.newChannel?.channelProperties?.apiKey}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleUpdateChannelValue(e.target.value, [
                          'channelProperties',
                          'apiKey',
                        ])
                      }
                      label={t('settings.channel.createForm.apiKey')}
                      name="apiKey"
                      required
                    />
                    <CustomError name="channelProperties.apiKey" />
                  </div>
                </>
              )}
              <div className="AddChannelModal__formField">
                <label>{t('settings.channel.syncFrequency.title')}</label>
                <Toggler
                  value={
                    context.newChannel?.channelProperties?.scheduler?.frequency
                  }
                  onToggle={(selection) => {
                    handleUpdateChannelValue(selection.key, [
                      'channelProperties',
                      'scheduler',
                      'frequency',
                    ]);
                  }}
                  toggleElements={[
                    {
                      label: t('settings.channel.syncFrequency.daily'),
                      key: ChannelSyncFrequency.Daily,
                    },
                    {
                      label: t('settings.channel.syncFrequency.weekly'),
                      key: ChannelSyncFrequency.Weekly,
                    },
                    {
                      label: t('settings.channel.syncFrequency.monthly'),
                      key: ChannelSyncFrequency.Monthly,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    );

  return (
    <div>
      <p className="AddChannelModal__adsconfigureTitle">
        <FormattedMessage
          id={
            mapSelectedIntegrationToConfigureTitle[
              context.selectedAccountType as AvailableIntegrationTypes
            ]
          }
        />
      </p>
      <p className="AddChannelModal__adsconfigureSubtitle">
        <FormattedMessage
          id={
            mapSelectedIntegrationToConfigureSubtitle[
              context.selectedAccountType as AvailableIntegrationTypes
            ]
          }
        />
      </p>
      <div className="AddChannelModal__iconWrapper">
        <Logo />
        <div className="AddChannelModal__dashedBorder"></div>
        <Icon className="icon" />
      </div>
    </div>
  );
};

export default AddChannelIntroduction;
