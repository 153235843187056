import { DependencyContainer } from 'DependencyContainer';
import { HttpOAuth2Client } from '../../../HttpOAuth2Client';
import { HarvestApiUrls, OAuth2ApiUrls } from '../../../urls';
import { AvailableIntegrationTypes, Channel } from '../../configuration/types';
import { AxiosResponse } from 'axios';

import { getIntegrationsRedirectUri } from '../../../environment';
import { TestChannelResponse } from 'features/configuration/integrationsState';
import { CreateChannelRequest } from '../types';

export default class IntegrationsClient extends HttpOAuth2Client {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async exchangeFacebookToken(token: string, partnerId: string): Promise<any> {
    const redirectUri = getIntegrationsRedirectUri();
    return this.get(
      OAuth2ApiUrls.getExchangeAccountTokenUrl(
        AvailableIntegrationTypes.Facebook,
        token,
        partnerId,
        redirectUri,
      ),
    );
  }

  async exchangeGoogleToken(token: string, partnerId: string): Promise<any> {
    const redirectUri = getIntegrationsRedirectUri();
    return this.get(
      OAuth2ApiUrls.getExchangeAccountTokenUrl(
        AvailableIntegrationTypes.Google,
        token,
        partnerId,
        redirectUri,
      ),
    );
  }

  async connectFacebookAccount(
    accountId: string,
    partnerId: string,
  ): Promise<any> {
    return await this.post(
      OAuth2ApiUrls.getConnectAccountUrl(
        AvailableIntegrationTypes.Facebook,
        accountId,
        partnerId,
      ),
      {},
    );
  }

  async connectGoogleAccount(
    accountId: string,
    partnerId: string,
  ): Promise<any> {
    return await this.post(
      OAuth2ApiUrls.getConnectAccountUrl(
        AvailableIntegrationTypes.Google,
        accountId,
        partnerId,
      ),
      {},
    );
  }

  async testFacebookChannel(
    channelId: string,
    partnerId: string,
  ): Promise<AxiosResponse<TestChannelResponse>> {
    return await this.post(
      OAuth2ApiUrls.getTestChannelUrl(
        AvailableIntegrationTypes.Facebook,
        channelId,
        partnerId,
      ),
      {},
    );
  }

  async testGoogleChannel(
    channelId: string,
    partnerId: string,
  ): Promise<AxiosResponse<TestChannelResponse>> {
    return await this.post(
      OAuth2ApiUrls.getTestChannelUrl(
        AvailableIntegrationTypes.Google,
        channelId,
        partnerId,
      ),
      {},
    );
  }

  async testHubspotChannel(
    channelId: string,
    partnerId: string,
  ): Promise<AxiosResponse<TestChannelResponse>> {
    return await this.post(
      OAuth2ApiUrls.getTestChannelUrl(
        AvailableIntegrationTypes.Hubspot,
        channelId,
        partnerId,
      ),
      {},
    );
  }

  async testBrazeChannel(
    channelId: string,
    partnerId: string,
  ): Promise<AxiosResponse<TestChannelResponse>> {
    return await this.post(
      OAuth2ApiUrls.getTestChannelUrl(
        AvailableIntegrationTypes.Braze,
        channelId,
        partnerId,
      ),
      {},
    );
  }

  async testMailChimpChannel(
    channelId: string,
    partnerId: string,
  ): Promise<AxiosResponse<TestChannelResponse>> {
    return await this.post(
      OAuth2ApiUrls.getTestChannelUrl(
        AvailableIntegrationTypes.MailChimp,
        channelId,
        partnerId,
      ),
      {},
    );
  }

  async reAuthChannel(
    code: string,
    channelId: string,
    partnerId: string,
  ): Promise<AxiosResponse<TestChannelResponse>> {
    return await this.put(
      OAuth2ApiUrls.getReAuthChannelUrl(
        channelId,
        partnerId,
        code,
        getIntegrationsRedirectUri(),
      ),
      {},
    );
  }

  async getAdAccounts(
    accountType: AvailableIntegrationTypes,
    partnerId: string,
  ): Promise<AxiosResponse<TestChannelResponse>> {
    return await this.get(
      OAuth2ApiUrls.getAdAccountsUrl(accountType, partnerId),
    );
  }

  async createNewConnection(
    request: CreateChannelRequest,
    partnerId: string,
  ): Promise<AxiosResponse<Channel>> {
    return await this.post(
      HarvestApiUrls.createNewConnection(partnerId),
      request,
    );
  }

  async editConnection(
    request: CreateChannelRequest,
    channelId: string,
    partnerId: string,
  ): Promise<AxiosResponse<Channel>> {
    return await this.patch(
      HarvestApiUrls.editConnection(channelId, partnerId),
      request,
    );
  }
}
