import React, { FunctionComponent, useEffect } from 'react';
import {
  Icons,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  notify,
} from 'plume-ui';

import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';

import { useTranslation } from 'react-i18next';
import { Channel } from 'features/configuration/types';
import { DependencyContainer } from 'DependencyContainer';
import { useRecoilValue } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { Maybe } from 'types';
import { ChannelSyncFrequency } from 'features/integrations/types';
import { useChannels } from 'features/configuration/hooks/useChannels';

interface UpdateFrequencyModalProps {
  channel: Channel;
}

const { channelsService } = new DependencyContainer();

const UpdateFrequencyModal = ({ channel }: UpdateFrequencyModalProps) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const { runFetch } = useChannels();
  const partnerId = useRecoilValue(partnerIdAtom);
  const [frequency, setFrequency] = React.useState<
    Maybe<ChannelSyncFrequency>
  >();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleCheck = (value: ChannelSyncFrequency) => {
    setFrequency(value);
  };

  const handleSave = async () => {
    if (!partnerId || !frequency) return;
    try {
      setLoading(true);
      await channelsService.editChannelSyncFrequency(
        partnerId,
        channel.channelId,
        frequency,
      );
      runFetch(true);
      notify({
        type: 'success',
        title: t('success'),
        body: t('settings.channel.frequencyModal.success'),
      });
      hideModal(MODAL_TYPES.FREQUENCY_MODAL);
      setLoading(false);
    } catch (error) {
      notify({
        type: 'error',
        title: t('error'),
        body: t('somethingWentWrong'),
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (channel?.channelProperties?.scheduler?.frequency)
      setFrequency(channel.channelProperties.scheduler.frequency);
  }, [channel]);

  return (
    <Modal
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} UpdateFrequencyModal`,
      })}
      isOpen={true}
      onRequestClose={() => hideModal(MODAL_TYPES.FREQUENCY_MODAL)}
    >
      <ModalHeader title={t('settings.channel.frequencyModal.title')} />
      <ModalBody
        classes={(current) => ({
          ...current,
          root: `${current.root} UpdateFrequencyModal__modalBody`,
        })}
      >
        <p className="UpdateFrequencyModal__description">
          {t('settings.channel.frequencyModal.description')}
        </p>
        <div className="UpdateFrequencyModal__checkboxGroup">
          <Checkbox
            checked={frequency === ChannelSyncFrequency.Daily}
            onChange={(e) => {
              if (e.target.checked) handleCheck(ChannelSyncFrequency.Daily);
            }}
            label={t(`settings.channel.syncFrequency.daily`)}
          />
          <Checkbox
            checked={frequency === ChannelSyncFrequency.Weekly}
            onChange={(e) => {
              if (e.target.checked) handleCheck(ChannelSyncFrequency.Weekly);
            }}
            label={t(`settings.channel.syncFrequency.weekly`)}
          />
          <Checkbox
            checked={frequency === ChannelSyncFrequency.Monthly}
            onChange={(e) => {
              if (e.target.checked) handleCheck(ChannelSyncFrequency.Monthly);
            }}
            label={t(`settings.channel.syncFrequency.monthly`)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => hideModal(MODAL_TYPES.FREQUENCY_MODAL)}
          styleVariant="tertiary-grey"
        >
          {t('cancel')}
        </Button>
        <Button
          disabled={loading || !frequency}
          onClick={handleSave}
          styleVariant="superprimary"
        >
          {t('save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateFrequencyModal;
