import { DependencyContainer } from 'DependencyContainer';
import { useChannels } from 'features/configuration/hooks/useChannels';
import {
  AvailableIntegration,
  AvailableIntegrationTypes,
  Channel,
} from 'features/configuration/types';
import { useAudience } from 'features/generate/audience/hooks/useAudience';
import { SegmentTreeData } from 'features/generate/audience/types';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import {
  Button,
  Dropdown,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  notify,
  Panel,
  PanelFooter,
  PendingContent,
  Status,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import {
  segmentTreeAtom,
  selectedSegmentAtom,
} from 'store/state/audienceFlowState';
import { channelsAtom } from 'store/state/channelState';
import { generateKey, wait } from '../../utils/helpers';
import classNames from 'classnames';
import { useSyncs } from 'features/syncs/hooks/useSyncs';
import { useRecoilState } from 'recoil';
import { syncsAtom } from 'store/state/syncsState';
import FormattedMessage from '../../utils/components/FormattedMessage';
import SyncTestResult from './SyncTestResult';
import { Routes, TIMEOUT_FOR_SYNC_MODAL_CLEANUP } from '../../config';

import { SyncStateTypes } from 'features/syncs/types';
import FieldsToExport from './FieldsToExport';
import { PanelStyles } from 'plume-ui/dist/components/Panel/Panel';
import { TestChannelResponse } from 'features/configuration/integrationsState';
import { availableIntegrations } from 'features/configuration/config';
import useWithConfirmation from 'features/generate/audience/hooks/useWithConfirmation';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import { AddChannelModal } from 'features/configuration/components/AddChannelModal/AddChannelModal';
import { ConfigChannelModalContext } from 'features/configuration/components/AddChannelModal/AddChannelModalContext';

const { integrationsService } = new DependencyContainer();

const defaultChannel = {
  channelId: '',
  channelType: '',
  channelDescription: '',
  channelName: '',
  createdBy: '',
  createdAt: '',
  adAccountId: '',
};

const mapStatuses: Record<string, string> = {
  success: 'success',
  failure: 'error',
  error: 'error',
};

interface CreateSyncModalProps {
  initialChannel?: Channel;
}

export const CreateSyncModal: FunctionComponent<CreateSyncModalProps> = ({
  initialChannel,
}) => {
  const { t } = useTranslation();
  const [channels, setChannels] = useState<Channel[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<Channel>(
    defaultChannel,
  );

  const [selectedSegment, setSelectedSegment] = useState<
    SegmentTreeData | undefined
  >();
  const [selectedSegmentInStore, setSelectedSegmentInStore] = useRecoilState(
    selectedSegmentAtom,
  );

  const syncs = useRecoilValue(syncsAtom);
  const navigate = useRedirectToRoute();
  const withConfirmation = useWithConfirmation();
  const { loading: channelLoading, error: channelError } = useChannels();
  const { loading: segmentsLoading, error: segmentsError } = useAudience();
  const fetchedChannels = useRecoilValue(channelsAtom);
  const segments = useRecoilValue(segmentTreeAtom);
  const { syncsService } = new DependencyContainer();
  const partnerId = useRecoilValue(partnerIdAtom);

  const [syncTestLoading, setSyncTestLoading] = useState(false);
  const [syncSaveComplete, setSyncSaveComplete] = useState(false);
  const [syncIsSaving, setSyncIsSaving] = useState(false);
  const [syncTestError, setSyncTestError] = useState<any>();
  const [syncTestResult, setSyncTestResult] = useState<TestChannelResponse>();
  const { runFetch } = useSyncs({
    initiallyThrottled: syncSaveComplete,
  });

  const { closeAllModals, showModal } = useGlobalModalContext();

  useEffect(() => {
    if (initialChannel) {
      setSelectedChannel(initialChannel);
    }
  }, [initialChannel]);

  const channelSelected = Boolean(selectedChannel.channelId);

  const selectedIntegration = useMemo(
    () =>
      availableIntegrations.find((i) => i.id === selectedChannel.channelType),
    [selectedChannel.channelType],
  );

  useEffect(() => {
    if (selectedSegmentInStore || selectedSegment) {
      setChannels(fetchedChannels);
    }
  }, [selectedSegmentInStore, selectedSegment, fetchedChannels]);

  useEffect(() => {
    setSyncTestError(undefined);
    setSyncTestResult(undefined);
  }, [selectedChannel]);

  const getStatus = () => {
    if (syncTestError) {
      return 'error';
    }
    if (!syncTestResult?.status || syncTestLoading) {
      return 'loading';
    }
    return mapStatuses[syncTestResult.status];
  };

  const handleSegmentSelect = (segment: SegmentTreeData) => {
    setSelectedSegment(segment);
    setSelectedSegmentInStore(segment);
  };

  const handleRunTest = async () => {
    const { channelId, channelType } = selectedChannel;

    if (!channelType || channelType === '' || !partnerId) {
      return;
    }

    try {
      setSyncTestLoading(true);
      setSyncTestError('');
      const result = await integrationsService.testChannel(
        channelType as AvailableIntegrationTypes,
        channelId,
        partnerId,
      );

      setSyncTestResult(result);
    } catch (error) {
      setSyncTestError(error);
    } finally {
      setSyncTestLoading(false);
    }
  };

  const clearModalState = () => {
    setChannels([]);
    setSelectedChannel(defaultChannel);
  };

  const hasSync = (channel: Channel): boolean => {
    if (!syncs) {
      return false;
    }

    const segmentSyncs = syncs.filter((s) => {
      return (
        s.segmentId === selectedSegmentInStore?.segmentId ||
        s.segmentId === selectedSegment?.segmentId
      );
    });

    const sync = segmentSyncs.find((s) => {
      return s.channel.channelId === channel.channelId;
    });

    return Boolean(sync);
  };

  const handleCloseModal = async () => {
    clearModalState();
    await runFetch();
    closeAllModals();

    setTimeout(() => {
      setSyncSaveComplete(false);
      setSelectedSegment(undefined);
      setSelectedSegmentInStore(undefined);
    }, TIMEOUT_FOR_SYNC_MODAL_CLEANUP);
  };

  const handleSave = async () => {
    if (syncIsSaving) {
      return;
    } else {
      setSyncIsSaving(true);
    }

    if (!selectedSegmentInStore) {
      return;
    }
    try {
      let segmentIdToSync: string | undefined;

      segmentIdToSync = selectedSegmentInStore.segmentId
        ? selectedSegmentInStore.segmentId
        : selectedSegment?.segmentId;

      if (segmentIdToSync && selectedChannel) {
        const selectedChannelId = selectedChannel.channelId;

        const sync = [
          {
            channelId: selectedChannelId,
            state: 'active' as SyncStateTypes,
          },
        ];

        await syncsService.addSync(partnerId, segmentIdToSync, sync);
        setSyncSaveComplete(true);
        notify({
          id: 'syncCreated',
          title: t('syncModal.syncCreated'),
          body: '',
          type: 'success',
          showDismiss: false,
          mustDismiss: false,
        });

        await handleCloseModal();
      }
    } catch (error) {
      setSyncIsSaving(false);
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
    }
  };

  const handleSelectChannel = (
    integration: AvailableIntegration,
    channel?: Channel,
  ) => {
    if (channel) {
      setSelectedChannel(channel);
      return;
    } else {
      withConfirmation({
        title: t('syncModal.setupConnection', {
          channel: t(integration.titleId),
        }),
        body: t('syncModal.setupConnectionBody', {
          channel: t(integration.titleId),
        }),
        confirmButtonLabel: t('continue'),
        onConfirm: async () => {
          closeAllModals();
          navigate(Routes.Connections);
          // voodoo
          // I hate this but it won't open the modal otherwise
          await wait(200);
          showModal(
            MODAL_TYPES.ADD_CHANNEL_MODAL,
            {
              preSelectIntegration: integration,
              afterFinish: async (channel: Channel) => {
                navigate(Routes.CreateAudience);
                await wait(200);
                showModal(
                  MODAL_TYPES.CREATE_SYNC_MODAL,
                  { initialChannel: channel },
                  MODAL_TYPES.CREATE_SYNC_MODAL,
                );
              },
            },
            MODAL_TYPES.ADD_CHANNEL_MODAL,
          );
        },
        maxWidth: 340,
      });
    }
  };

  return (
    <PendingContent
      loading={channelLoading || segmentsLoading}
      isError={Boolean(channelError) || Boolean(segmentsError)}
    >
      <Panel
        classes={(current: PanelStyles) => ({
          ...current,
          root: `${current.root} CreateSyncModal`,
        })}
        title={t('syncModal.createSync')}
        open
        setOpen={() => handleCloseModal()}
      >
        <div className="CreateSyncModal__body">
          {selectedSegmentInStore && (
            <div className="CreateSyncModal__bodyHeader">
              <div className="CreateSyncModal__bodyTitle">
                {t('syncModal.destinationPlatform')}
              </div>
              <div className="CreateSyncModal__bodyDescription">
                {channelSelected
                  ? t(`settings.channel.${selectedChannel.channelType}`)
                  : t('syncModal.selectDestinationPlatform')}
              </div>
            </div>
          )}
          {!selectedSegmentInStore && (
            <>
              <div>
                <FormattedMessage id="syncModal.selectSegment" />
              </div>
              <Dropdown
                classes={(current) => ({
                  ...current,
                  root: `${current.root} CreateSyncModal__segmentDropdown`,
                })}
                label={
                  selectedSegment
                    ? selectedSegment.segmentName
                    : t('syncModal.selectSegment')
                }
                closeOnItemClick
                searchPlaceholder=""
                searchBar
              >
                {segments.map((segment) => {
                  return (
                    <DropdownItem
                      key={generateKey()}
                      onClick={() => handleSegmentSelect(segment)}
                    >
                      {segment.segmentName}
                    </DropdownItem>
                  );
                })}
              </Dropdown>
            </>
          )}
          {!channelSelected && (
            <div className="CreateSyncModal__channelContainer">
              {availableIntegrations.map((channel) => {
                const existingChannel = channels.find(
                  (ch) => ch.channelType === channel.id,
                );
                const status = (() => {
                  if (!existingChannel) return 'notConfigured';
                  if (!hasSync(existingChannel)) return 'readyToSync';
                  return 'synced';
                })();
                return (
                  <div
                    role="button"
                    onClick={() =>
                      status !== 'synced'
                        ? handleSelectChannel(channel, existingChannel)
                        : undefined
                    }
                    key={channel.id}
                    className={classNames('ChannelItem', {
                      'ChannelItem--selectable': status !== 'synced',
                    })}
                  >
                    {channel.icon}
                    <div className="ChannelItem__title">
                      {t(channel.titleId)}
                    </div>
                    <div
                      className={classNames('ChannelItem__status', {
                        'ChannelItem__status--ready': status === 'readyToSync',
                        'ChannelItem__status--synced': status === 'synced',
                      })}
                    >
                      <Status
                        label={
                          {
                            readyToSync: t('syncModal.readyToSync'),
                            notConfigured: t('syncModal.notConfigured'),
                            synced: t('syncModal.synced'),
                          }[status]
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {channelSelected && (
            <>
              <div className="CreateSyncModal__channelCard">
                <div className="CreateSyncModal__channelCardIcon">
                  {selectedIntegration?.icon}
                </div>
                <div className="CreateSyncModal__channelDetails">
                  {selectedChannel.adAccountId && (
                    <div className="CreateSyncModal__channelAccountId">
                      {t('syncModal.accountId')} {selectedChannel.adAccountId}
                    </div>
                  )}
                  <Status label={t('syncModal.readyToSync')} />
                </div>
              </div>

              <div className="CreateSyncModal__testContainer">
                <div className="CreateSyncModal__testTitle">
                  {t('syncModal.testConnectionTitle')}
                </div>
                <div className="CreateSyncModal__testDescription">
                  {t('syncModal.testConnectionDescription')}
                </div>
                <div className="CreateSyncModal__runTestBtn">
                  <Button
                    disabled={selectedChannel.channelType === ''}
                    onClick={() => handleRunTest()}
                  >
                    <FormattedMessage
                      id={
                        syncTestLoading
                          ? 'loading'
                          : 'settings.channel.runTestButton'
                      }
                    />
                  </Button>
                </div>
                {(syncTestLoading || syncTestResult || syncTestError) && (
                  <>
                    <div className="CreateSyncModal__testResultHeading">
                      <FormattedMessage id="settings.channel.testingConnection" />
                    </div>
                    <SyncTestResult
                      status={getStatus()}
                      syncTestResult={{
                        result: getStatus(),
                        message: syncTestResult?.error_message,
                      }}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <PanelFooter>
          {channelSelected && (
            <Button
              onClick={() => setSelectedChannel(defaultChannel)}
              styleVariant="tertiary-grey"
              classes={(current) => {
                return {
                  ...current,
                  container: `${current.container} CreateSyncModal__backButton`,
                };
              }}
            >
              {t('back')}
            </Button>
          )}
          <Button
            onClick={handleSave}
            styleVariant="superprimary"
            disabled={!channelSelected || syncIsSaving}
          >
            <FormattedMessage id="save" />
          </Button>
        </PanelFooter>
      </Panel>
    </PendingContent>
  );
};
