import {
  AddChannelModalTabs,
  AvailableIntegration,
  AvailableIntegrationTypes,
} from './types';
import { Icons } from 'plume-ui';
import React from 'react';

import hubspotIcon from '../../assets/png/hubspot.png';
import brazeIcon from '../../assets/png/braze.png';
import mailchimpIcon from '../../assets/png/mailchimp.png';

export const tabBarTitleIds: Record<AddChannelModalTabs, string> = {
  [AddChannelModalTabs.Choose]: 'settings.channel.chooseLabel',
  [AddChannelModalTabs.Configure]: 'settings.channel.configureLabel',
  [AddChannelModalTabs.Test]: 'settings.channel.testLabel',
};

export const availableIntegrations: AvailableIntegration[] = [
  {
    id: AvailableIntegrationTypes.Facebook,
    titleId: 'settings.channel.facebookAds',
    icon: <Icons.FacebookIcon />,
  },
  {
    id: AvailableIntegrationTypes.Google,
    titleId: 'settings.channel.googleAds',
    icon: <Icons.GoogleIcon />,
  },
  {
    id: AvailableIntegrationTypes.Hubspot,
    titleId: 'settings.channel.hubspot',
    icon: <img src={hubspotIcon} alt="" />,
  },
  {
    id: AvailableIntegrationTypes.Braze,
    titleId: 'settings.channel.braze',
    icon: <img src={brazeIcon} alt="" />,
  },
  {
    id: AvailableIntegrationTypes.MailChimp,
    titleId: 'settings.channel.mailchimp',
    icon: <img src={mailchimpIcon} alt="" />,
  },
];

export const scrollContainer = {
  marginRight: '2px',
  maxHeight: '628px',
  padding: '32px',
  height: '100vh',
};

export const availableConnectionMethodsForChannel: Record<
  AvailableIntegrationTypes,
  ('oauth2' | 'token' | 'apiKey')[]
> = {
  [AvailableIntegrationTypes.Facebook]: ['oauth2'],
  [AvailableIntegrationTypes.Google]: ['oauth2'],
  [AvailableIntegrationTypes.Hubspot]: ['token'],
  [AvailableIntegrationTypes.Braze]: ['token'],
  [AvailableIntegrationTypes.MailChimp]: ['apiKey'],
};
